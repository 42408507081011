import React from 'react';
import '../../../App.scss';
import {Link} from "react-router-dom";
import {Row, Col,Card, PageHeader, Tabs, Collapse, Spin, Button  } from 'antd'
import rest from '../../../helpers/rest';
import SingleGoodComponent from '../../catalog/singlegood';
import TotalPrice from '../../requests/totalprice';
import RequestCommentsComponent from '../../comments/index'
const { TabPane } = Tabs;
const { Panel } = Collapse;

class ExchangeCartRequest extends React.Component{
    constructor(props){
        super(props);  
        this.state = {
            loading: true,
            requests: [],   
            selftcostdiscount: false         
        }      
        this.submitRequest = this.submitRequest.bind(this)
        this.deleteFromCart = this.deleteFromCart.bind(this);
        this.getRequests = this.getRequests.bind(this);
    }
    
    componentWillMount(){                
        this.getRequests();
    }

    getRequests(){
        rest.get('/api/Cart/getbyrequest', {type: 'exchange',request: this.props.data.id}).then(data => {            
            this.setState({requests: data.data.data.cart, loading: false, selftcostdiscount: data.data.data.selftcostdiscount, percentdiscount: (data.data.data.percentdiscount && data.data.data.percentdiscount.length > 0) ? data.data.data.percentdiscount[0] : null});
        })
    }

    submitRequest(){        
        this.setState({loading: true})
        rest.update('/api/Requests/changestatus', {requestid: this.props.data.id, status: 'done'}).then(data => {            
            
            this.props.updateRequests();
        })
    }

    // Метод удаляет позицию из состояния
    deleteFromCart(id){
        let list = this.state.requests;
        let res_list = list.filter(item => item.id !== id);        
        this.setState({requests: res_list})
    }
    getPercentDiscount(){    
        if(this.state.percentdiscount && this.state.percentdiscount.DISCOUNT.match(/\d+/g)){
          return parseInt(this.state.percentdiscount.DISCOUNT.match(/\d+/g)[0] || 0)
        }
        return null;
      }
    render(){
        return(
            
                <Spin spinning={this.state.loading}>
                    <TotalPrice onRef={ref => (this.child = ref)} type='exchange' id={this.props.data.id} updateRequests={this.props.updateRequests} />
                    <div className="stocklist">
                    {
                        this.state.requests.map((item, index) => {                            
                            return <SingleGoodComponent percent={this.getPercentDiscount()} selftcostdiscount={this.state.selftcostdiscount} update={() => this.child.getPrice()} type="exchange" tradingday={item.tradingday} key={index} deleteFromCart={(id) => this.deleteFromCart(id)} fromCart={true} fromStock={false} fromExchange={true} data={item} />
                        })
                    }
                    </div>
                    <div className="submitcartrequestbtnwrap">
                        {this.state.requests.length > 0 && <Button type="primary" className="submitcartrequestbtn" onClick={() => this.submitRequest()}>Подтвердить заказ</Button>}
                    </div>
                    <RequestCommentsComponent id={this.props.data.id} />
                </Spin>
                
                
                
            
            
        )
    }
}

export default ExchangeCartRequest;