import React from "react";
import "../../../App.scss";
import {ReactComponent as ListIcon} from '../../../assets/barcode.svg';
import { Modal, Button, message } from 'antd';
import StockSelectorComponent from "../../requests/miniproduct/selector/stockselector";
import BarcodeReader from 'react-barcode-reader'
import useScanDetection from 'use-scan-detection';
import rest from "../../../helpers/rest";
class ProductSelectorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalVisible: false, loading: false};
    this.handleScan = this.handleScan.bind(this);
  }


  handleScan(data){
    let input = data;    
    let id = input.substr(1, input.length);            
    message.info('Поиск позиции...')
    rest.get('/api/Stock/byincode', {incode: id}).then(data => {            
      this.props.onSelect(data.data.data.id);
    }).catch(e => {
      message.error('Ошибка')
    })
    
  }

  handleError(err){
    console.err(err)
  }
  
  render() {
    
    return (
      <div className="product__selector">
        <div className="product__selector__barcode">
          <ListIcon />
        </div>
        <div className="product__selector__info">
            <p>Отсканируйте товар или выберите вручную</p>
            <Button className="product__selector_btn" onClick={() => {              
              this.setState({modalVisible: true});
              }}>Выбрать товар</Button>
        </div>   
        <StockSelectorComponent onSelect={(id) => {
          this.setState({modalVisible: false});
          this.props.onSelect(id)
        }} visible={this.state.modalVisible} onClose={() => this.setState({modalVisible: false})} />       
         <BarcodeReader
          onError={(e) => this.handleError(e)}
          onScan={(e) => this.handleScan(e)}
          preventDefault={true}
          stopPropagation={true}
          timeBeforeScanTest={100}
          onScanButtonLongPressed={e => console.log(`onScanButtonLongPressed ${e}`)}
          minLength={4}
          
          />
      </div>
    );
  }
}





export default ProductSelectorComponent;
