import * as React from 'react';
import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';

class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { list } = this.props;
        return(
            <Breadcrumb style={{ margin: '16px 0' }}>
                {list !== undefined && list.map((value, index, array) => {
                    const isLast = array.length !== (index + 1);
                    return(
                    <Breadcrumb.Item key={index}>{isLast ? <NavLink to={value.key}>{value.title}</NavLink> : value.title}</Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        );
    }
}

export default Breadcrumbs;
