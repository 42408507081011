import React from "react";
import "../../App.scss";
import { Button, Card, message, Spin } from "antd";
import Breadcrumb from "../common/breadcrumb";
import AppContext from "../../components/common/appContext";
import { Form, Input, Radio } from "antd";
import { PageHeader } from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../helpers/rest";

class SettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Настройки приложения", key: "/stock/" },
    ];
    this.state = {
        instload: true
    }
    this.formRef = React.createRef();
    this.saveSocial = this.saveSocial.bind(this)
  }

  componentWillMount() {      
    document.title = "Настройки | Букетная.ОПТ";
    rest.get('/api/Settings', {setkey: 'contacts'}).then(data => {        
        this.setState({socialdata: data.data.data, instload: false}, () => {
            this.formRef.current.setFieldsValue({
                instagram: this.state.socialdata.filter(item => item.name === 'instagram')[0].value,
                email: this.state.socialdata.filter(item => item.name === 'email')[0].value,
                phone: this.state.socialdata.filter(item => item.name === 'phone')[0].value,
                memail: this.state.socialdata.filter(item => item.name === 'memail')[0].value,
                nameOrganization: this.state.socialdata.filter(item => item.name === 'nameOrganization')[0].value,
                paymentAccount: this.state.socialdata.filter(item => item.name === 'paymentAccount')[0].value,
                bic: this.state.socialdata.filter(item => item.name === 'BIC')[0].value
            });
        });
        
    })
  }

  saveSocial() {
    let socialdata = this.formRef.current.getFieldsValue();    
    this.setState({instload: true})
    rest.update('/api/Settings', {setkey: 'contacts', settings: socialdata}).then(data => {        
        this.setState({instload: false});
        message.success('Контактные данные сохранены')
    }).catch(err => {
        this.setState({instload: false});
        message.error('Ошибка сохранения контактных данных')
    })
  }

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <Card key={2} className="content-card">
        <PageHeader
          className="site-page-header"
          onBack={() => this.props.history.goBack()}
          title="Настройки приложения"
        />
        <div className="settingsapp" style={{ padding: "20px" }}>
            <Spin spinning={this.state.instload}>
          <Card>
            <h2>Социальные сети</h2>
            <Form
              layout="vertical"
              name="socialsettings"
              ref={this.formRef}
              onFinish={this.saveSocial}
            >
              <Form.Item
                label="Instagram"
                name="instagram"
                tooltip="Введите логин в Instagram"
              >
                <Input placeholder="Instagram" />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                tooltip={{ title: "E-mail орагнизации" }}
              >
                <Input placeholder="example@domain.com" />
              </Form.Item>
              
              <Form.Item
                label="Номер телефона"
                name="phone"
                tooltip={{ title: "Номер телефона орагнизации" }}
              >
                <Input placeholder="89999999999" />
              </Form.Item>
              <Form.Item
                label="Дополнительный E-mail для заказов"
                name="memail"
                tooltip={{ title: "E-mail на который будут дублироваться заявки" }}
              >
                <Input placeholder="example@domain.com" />
              </Form.Item>
              <h2>Реквизиты</h2>
              <Form.Item
                label="Наименование организации"
                name="nameOrganization"
                tooltip="Введите наименование организации"
              >
                <Input placeholder="ИП Букетная" />
              </Form.Item>
              <Form.Item
                label="Расчетный счет"
                name="paymentAccount"
                tooltip="Введите расчетный счет"
              >
                <Input placeholder="40702810123450101230" />
              </Form.Item>
              <Form.Item
                label="БИК"
                name="bic"
                tooltip="Введите БИК"
              >
                <Input placeholder="042406608" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">Сохранить</Button>
              </Form.Item>
            </Form>
          </Card>
          
          </Spin>
        </div>
      </Card>,
    ];
  }
}

SettingsComponent.contextType = AppContext;

export default withRouter(SettingsComponent);
