import React from "react";
import "../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../common/breadcrumb";
import { Card, Row, Col, List, Modal, Button, Form, Input } from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import OrganizationEdit from "./lawedit/lawedit";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class Organizations extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Справочники", key: "/directory/" },
      { title: "Организации", key: "/organizations/" },
    ];
    this.formRef = React.createRef();
    this.formRefEdit = React.createRef();
    this.history = this.props.history;
    this.state = {
      cataspan: 24,
      organizationList: [1,2],
      loading: true,
      addModalVisible: false,
      formAddLoader: false,
      editModalVisible: false,
      activeOrg: {}
    };
    document.title = "Организации | Букетная.ОПТ";
    this.getOrganizations = this.getOrganizations.bind(this);

    this.addModalShow = this.addModalShow.bind(this);
    this.closeAddModa = this.closeAddModa.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this)
    this.submitEditForm = this.submitEditForm.bind(this)
    this.onSubmit = this.onSubmit.bind(this);
    this.submitAddForm = this.submitAddForm.bind(this);
    this.showEditOrg = this.showEditOrg.bind(this)
    this.closeEditModa = this.closeEditModa.bind(this)
  }

  componentWillMount() {
    
    this.getOrganizations();
  }

  getOrganizations() {
    this.setState({ loading: true });
    rest.get("/api/Organizations").then((organizations) => {
      this.setState({ organizationList: organizations.data.data, loading: false });
    });
  }

  onSubmit() {
    let formdata = this.formRef.current.getFieldsValue();
    
    this.setState({ formAddLoader: true });
    rest.post("/api/Organizations", formdata).then((res) => {
      
      this.closeAddModa();
      this.getOrganizations();
      //this.formRef.current.reset();
    });
  }

  onSubmitEdit() {
    let formdata = this.formRefEdit.current.getFieldsValue();    
    this.setState({ formAddLoader: true });    
    rest.update("/api/Organizations/", {orgName: formdata.orgName, orgId: this.state.activeOrg.orgId, INN: formdata.INN, BIK : formdata.BIK}).then((organizations) => {
      this.closeAddModa();
      this.getOrganizations();      
    });
  }

  addModalShow() {
    this.setState({ addModalVisible: true });
  }

  closeAddModa() {
    this.setState({editModalVisible: false, addModalVisible: false, formAddLoader: false });
  }
  closeEditModa(){
    this.setState({ editModalVisible: false, formAddLoader: false });
  }

  submitAddForm() {
    this.formRef.current.submit();  
  }

  submitEditForm() {
    this.formRefEdit.current.submit();  
  }

  deleteCat(orgId){
      this.setState({loading: true})
      rest.delete('/api/Organizations', {orgId:orgId}).then(() => {
          this.getOrganizations();
          message.success('Организация удалена');          
      })
  }

  showEditOrg(item){
    
      this.setState({editModalVisible: true, activeOrg: item})
      
      setTimeout(() => {
        this.formRefEdit.current.setFieldsValue({orgName: item.orgName, INN: item.INN, BIK: item.BIK})
      }, 100)
      
      //
  }

  render() {
    const style = { padding: "0px 20px" };
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Организации"
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.addModalShow()}
                >
                  Добавить
                </Button>,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={style} className="categorylist">
            <List
              itemLayout="horizontal"
              dataSource={this.state.organizationList}
              locale={{emptyText: 'Организации не найдены'}}
              renderItem={(item, index) => (
                <Skeleton
                  key={index}
                  title={false}
                  loading={this.state.loading}
                  active
                >
                  <List.Item
                    className="organizationList"
                    actions={[
                      <a key="list-loadmore-edit" onClick={() => this.showEditOrg(item)}>
                        <EditOutlined />
                      </a>,
                      <Popconfirm
                        title="Удалить организацию?"
                        onConfirm={() => this.deleteCat(item.orgId)}                        
                        okText="Да"
                        cancelText="Нет"
                      >
                        <a key="list-loadmore-more">
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta title={item.orgName} />
                  </List.Item>
                </Skeleton>
              )}
            ></List>
          </Col>
        </Row>
      </Card>,
      <Modal
      key={3}
      title="Редактирование организации"
      visible={this.state.editModalVisible}
      onCancel={this.closeEditModa}
      onOk={this.submitEditForm}
    >
      {this.state.formAddLoader ? (
        <Loading mini={true} />
      ) : (
        <Form
          {...layout}
          name="addorgform"
          ref={this.formRefEdit}
          onFinish={this.onSubmitEdit}
        >
          <Form.Item
            label="Название организации"
            name="orgName"
            rules={[
              { required: true, message: "Пожалуйста введите название организации" },
            ]}
          >
            <Input />
          </Form.Item>
          <OrganizationEdit />
        </Form>
      )}
    </Modal>,
      <Modal
        key={4}
        title="Добавление организации"
        visible={this.state.addModalVisible}
        onCancel={this.closeAddModa}
        onOk={this.submitAddForm}
      >
        {this.state.formAddLoader ? (
          <Loading mini={true} />
        ) : (
          <Form
            {...layout}
            name="addorgform"
            ref={this.formRef}
            onFinish={this.onSubmit}
          >
            <Form.Item
              label="Название организации"
              name="orgName"
              rules={[
                { required: true, message: "Пожалуйста введите название организации" },
              ]}
            >
              <Input />
            </Form.Item>
            <OrganizationEdit />
          </Form>
        )}
      </Modal>,
    ];
  }
}

Organizations.contextType = AppContext;

export default withRouter(Organizations);
