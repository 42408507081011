import React from "react";
import "../../App.scss";
import { Button, Layout, Modal, Spin } from "antd";
import {ThunderboltOutlined } from "@ant-design/icons";
import {withRouter} from "react-router-dom"
import {ReactComponent as ListIcon} from '../../assets/requests.svg';
import {ReactComponent as PhoneIcon} from '../../assets/phoneicon.svg';
import {ReactComponent as PreorderIcon} from '../../assets/pre.svg';
import AppContext from "../../components/common/appContext";
import rest from "../../helpers/rest";
const { Header, Content, Footer } = Layout;

class FastActionsComponent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false
        }
    }

    navto(path){
        this.props.history.push(path);
        this.props.onClose();
    }

    preorder = () => {
        this.setState({loading: true})
        rest.post('/api/Requests/preorder').then(data => {
            this.setState({loading: false})
            this.props.onClose();            
            this.props.history.push(`/preorder/${data.data.data}`);
        })
        
    }

    render(){
        return(
                            
            <Modal
        title="Панель быстрых дейсвий"
        centered
        key={22}
        visible={this.props.visible}        
        onCancel={() => this.props.onClose()}
        onOk={() => this.props.onClose()}
        width={1000}
        footer={[
            <Button key={33} type="primary" onClick={() => this.props.onClose()} >Закрыть</Button>
        ]}
      >
          <Spin key={1} spinning={this.state.loading}>
        <div key={255} className="fastactions__list">
            <div key={4} className="fastactions__list__item" onClick={() => this.navto('/simplerequest')}>                
                <span className="icon"><ThunderboltOutlined /></span>
                <span className="label">Быстрый заказ</span>
            </div>
            <div key={5} className="fastactions__list__item" onClick={() => this.navto('/usersrequests')}>
                <span className="icon"><ListIcon /></span>
                <span className="label">Заявки</span>
            </div>          
            <div key={6} className="fastactions__list__item" onClick={() => this.navto('/preorder')}>
                <span className="icon"><PreorderIcon style={{width: '40px', height: '40px', fill: '#0086ff'}} /></span>
                <span className="label">Предзаказы</span>
            </div>            
            <div key={7} className="fastactions__list__item" onClick={() => this.preorder()}>
                <span className="icon"><ListIcon /></span>
                <span className="label">Создать прездзаказ</span>
            </div>            
            <div key={8} className="fastactions__list__item" onClick={() => this.navto('/touchcalendar')}>
                <span className="icon"><PreorderIcon style={{width: '40px', height: '40px', fill: '#0086ff'}} /></span>
                <span className="label">Календарь звонков</span>
            </div> 
            <div key={9} className="fastactions__list__item" onClick={() => this.navto('/calls')}>
                <span className="icon"><PhoneIcon style={{width: '40px', height: '40px', fill: '#0086ff'}} /></span>
                <span className="label">История звонков</span>
            </div>            
        </div>
        </Spin>
      </Modal>      
        )
    }
}

FastActionsComponent.contextType = AppContext;
export default withRouter(FastActionsComponent);
