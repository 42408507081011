import React from 'react';
import '../App.scss';
import { Spin } from 'antd';


class Loading extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            str: this.props.str
        }
    }

    componentWillReceiveProps(next){
        this.setState({str: next.str})
    }

    render(){
        return(
            <div id={this.props.mini ? 'loading_mini' : 'loading'}>
                <Spin size="large" />
                {this.state.str && this.state.str.length > 0 ? <p className="loading__text">{this.state.str}</p> : ''}
            </div>
        )
    }
}

export default Loading;