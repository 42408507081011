import React from 'react';
import '../../App.scss';
import { Card } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import AppContext from '../../components/common/appContext';
import { PageHeader, Button, Typography } from 'antd';
import { withRouter, NavLink } from 'react-router-dom';
const { Text, Link, Title } = Typography;

class DirectoryComponent extends React.Component{
    constructor(props){
        super(props);
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Справочники", key: "/stock/" }
        ];
        this.directories = [
            {title: 'Категории', path: '/directory/category'},
            {title: 'Организации', path: '/organizations'},
            {title: 'Пользователи', path: '/users'},
            {title: 'Цвета', path: '/colors'},
            {title: 'Типы транзакций', path: '/directory/tstypes'},
            {title: 'Способы оплаты', path: '/directory/payment'},
            {title: 'Торговые дни', path: '/directory/tradingdays'},                 
            {title: 'Постоянные величины', path: '/const'},                 
            {title: 'Настройки', path: '/directory/settings'},                 
        ]
    }

    componentWillMount(){
        document.title = 'Справочники | Букетная.ОПТ';        
    }

    render(){        
        return [
                <Breadcrumb key={1} style={{ margin: '16px 0' }} list={this.breadcrumbs} />,
                <Card
                    key={2}
                    className="content-card"                    
                >
                    <PageHeader
                        className="site-page-header"
                        onBack={() => this.props.history.goBack()}
                        title="Справочники"                        
                    />
                    <div className="directorylist">
                        {
                            this.directories.map((item, index) => {
                                return <NavLink to={item.path} key={index}>
                                    <Card>
                                        <div className="directorylist__item">
                                            <Title level={4}>{item.title}</Title>
                                        </div>
                                    </Card>
                                    </NavLink>
                            })
                        }                        
                    </div>
                </Card>
            ]
        
    }
}

DirectoryComponent.contextType = AppContext;

export default withRouter(DirectoryComponent);