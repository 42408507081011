import React from "react";
import "../../App.scss";
import { Link } from "react-router-dom";
import { Layout, Modal } from "antd";
import TopBar from "../topbar/topbar";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as InstLogo } from "../../assets/instagram.svg";
import { ReactComponent as MailIcon } from "../../assets/email.svg";
import { ReactComponent as PhoneIcon } from "../../assets/phone.svg";
import {ThunderboltOutlined } from "@ant-design/icons";
import { ReactComponent as LogoMini } from "../../assets/logo_mini.svg";
import AppContext from "../../components/common/appContext";
import { isIOS } from "react-device-detect";
import { NavLink } from "react-router-dom";
import rest from "../../helpers/rest";
import FastActionsComponent from "./fastactions";
import Emitter from "../../helpers/events";
const { Header, Content, Footer } = Layout;

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navopen: false,
      social: [],
    };
    this.closeNav = this.closeNav.bind(this);
    this.intervalnav = null;
    this.listInnerRef = React.createRef();
  }

  closeNav(event, imp = false) {
    if (imp) {
      this.setState({ navopen: false });
    }
    if (event) {
      var isClickInside = true;
      if (document.getElementById("navigation")) {
        isClickInside = document
          .getElementById("navigation")
          .contains(event.target);
      }

      if (!isClickInside) {
        this.setState({ navopen: false });
      }
    }
  }

  componentWillMount() {
    //console.log(this.context)
    rest.get("/api/Settings", { setkey: "contacts" }).then((res) => {      
      this.setState({ social: res.data.data });
    });
    document.addEventListener("click", this.closeNav);
    if (window.innerWidth > 767) {
      this.intervalnav = setInterval(() => {
        if (document.getElementById("navigation")) {
          clearInterval(this.intervalnav);
          document
            .getElementById("navigation")
            .addEventListener("mouseover", (e) => {
              this.setState({ navopen: true });
            });
          document
            .getElementById("navigation")
            .addEventListener("mouseout", (e) => {
              this.setState({ navopen: false });
            });
        }
      }, 100);
    } else {
      this.intervalnav = setInterval(() => {
        if (true && document.getElementById("navigation")) {
          document.getElementById('content').style.display = "inline-block";
          document.getElementById('content').style.marginBottom = "35px";
          document.getElementById('content').style.width = "-webkit-fill-available";          
          document.getElementById("navigation").classList.add("h80");
          clearInterval(this.intervalnav);
        }
      }, 100);
    }
  }

  openFastActions(){
    
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('content');
    if (this.isBottom(wrappedElement)) {
      //console.log('header bottom reached');
      //document.removeEventListener('scroll', this.trackScrolling);
      Emitter.emit('scrollend');
    }
  };

  componentWillUnmount(){
    document.removeEventListener('scroll', this.trackScrolling);
  }

  componentDidMount(){
    document.addEventListener('scroll', this.trackScrolling);
  }

  isBottom = (el) => {    
    return Math.floor(el.getBoundingClientRect().bottom) <= window.innerHeight;
  }

  render() {
    return (
      <div id="layout" className="layout">
        <div id="navigation" className={this.state.navopen ? "open" : ""}>
          <div className="topnavblock">
            <div className="logo">
              {this.state.navopen ? (
                <Logo className="big" />
              ) : (
                <LogoMini className="small" />
              )}
            </div>
            <ul className="nav">
              <li key={300} className="hideopen hidedesc">
                <button
                  className={
                    this.state.navopen ? "arrow open rot" : "arrow open"
                  }
                  onClick={() =>
                    this.setState({ navopen: !this.state.navopen })
                  }
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 9 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711L2.34315 15.0711C1.95262 15.4616 1.31946 15.4616 0.928932 15.0711C0.538408 14.6805 0.538408 14.0474 0.928932 13.6569L6.58579 8L0.928932 2.34315C0.538408 1.95262 0.538408 1.31946 0.928932 0.928932C1.31946 0.538408 1.95262 0.538408 2.34315 0.928932L8.70711 7.29289ZM7 7H8V9H7V7Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </li>
              {this.props.routes
                .filter((elem) => elem.access.includes(this.context.usertype))
                .map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        window.location.pathname == item.path ? "active" : ""
                      }
                    >
                      <NavLink
                        onClick={() => this.closeNav(null, true)}
                        to={item.path}
                      >
                        <div className="nav_item_wrap">
                          <div className="icon">{item.icon}</div>
                          <span>{item.label}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="social">
            <ul>
              {this.state.social.map((item, index) => {
                switch (item.name) {
                  case "instagram": {
                    return (
                      <li key={index}>
                        <a href={`https://instagram.com/${item.value}`} target="_blank">
                          <InstLogo className="icon" />
                            <span>@{item.value}</span>
                        </a>
                      </li>
                    );
                    break;
                  }
                  case "email": {
                    return (
                      <li key={index}>
                        <a href={`mailto:${item.value}`} target="_blank">
                          <MailIcon className="icon" />
                          <span>{item.value}</span>
                        </a>
                      </li>
                    );
                    break;
                  }
                  case "phone": {
                    return (
                      <li key={index}>
                        <a href={`tel:${item.value}`} target="_blank">
                          <PhoneIcon className="icon" />
                          <span>{item.value}</span>
                        </a>
                      </li>
                    );

                    break;
                  }
                }
              })}
            </ul>
          </div>
          <div className="bottomblock">
            <button
              className={this.state.navopen ? "arrow open rot" : "arrow open"}
              onClick={() => this.setState({ navopen: !this.state.navopen })}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711L2.34315 15.0711C1.95262 15.4616 1.31946 15.4616 0.928932 15.0711C0.538408 14.6805 0.538408 14.0474 0.928932 13.6569L6.58579 8L0.928932 2.34315C0.538408 1.95262 0.538408 1.31946 0.928932 0.928932C1.31946 0.538408 1.95262 0.538408 2.34315 0.928932L8.70711 7.29289ZM7 7H8V9H7V7Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
        <div id="content"  className="content__wrap" >
          <TopBar usertype={this.context.usertype} />
          <div className="content" >
            {/* <div className="content">
                        <div className="content__inner">
                        {
                            this.props.children
                        }
                        </div>
                    </div> */}
            <Content style={{ padding: "0 50px" }}>
              {this.props.children}
            </Content>
            <div onClick={() => {this.setState({visiblefastactions: true})}} className={`actions__button ${this.context.usertype == 'admin' && 'show'}`}><ThunderboltOutlined style={{color: 'white', fontSize: '30px'}} /></div>
            <FastActionsComponent visible={this.state.visiblefastactions} onClose={() => this.setState({visiblefastactions : false})} />
            {/* <div className="copy">
                        <p>
                            Сделано в
                            <a target="_blank" href="https://mowins.ru"> Mowins </a>
                        </p>
                    </div> */}
          </div>
        </div>
      </div>
    );
  }
}

AppLayout.contextType = AppContext;

export default AppLayout;
