import React from "react";
import "../../App.scss";
import { Card, Form, Spin, Input, InputNumber, Select } from "antd";
import Breadcrumb from "../common/breadcrumb";
import AppContext from "../../components/common/appContext";
import { PageHeader, Button } from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../helpers/rest";
import * as moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { sm: 24 },
  wrapperCol: { sm: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

class StockEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.onAddToStock = this.onAddToStock.bind(this);
    this.formRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
    this.setLoad = this.setLoad.bind(this);
    this.state = {
      addloading: true,
      tradinglist: [],
    };
  }

  submitForm() {
    this.formRef.current.submit();
  }

  resetForm(){
    
    this.formRef.current.setFieldsValue({
      growth: null,
          country: null,
          multiplicity: null,
          price: null,
          available: null,
          more: null,
          tradingday: null,
          available: null,
          tradingday: null
    });
  }

  componentWillMount() {

    rest.get('/api/Trading').then(data => {
      
      this.setState({tradinglist: data.data.data})
    })


    this.props.onRef(this);
    if (this.props.edit) {
      this.formRef.current.setFieldsValue({});
    }

    rest.get("/api/Catalog", { id: this.props.catalogid }).then((data) => {
      let catalogdata = data.data.data.list[0] || {};
      this.formRef.current.setFieldsValue({
        name: catalogdata.name,
      });
      if (this.props.data) {
        
        this.formRef.current.setFieldsValue({
          growth: this.props.data.growth,
          country: this.props.data.country,
          multiplicity: this.props.data.multiplicity,
          price: this.props.data.price,
          available: this.props.data.available,
          more: this.props.data.more,
          tradingday: this.props.data.tradingday,
          selfcost: this.props.data.selfcost,
          WR_PRICE: this.props.data.WR_PRICE
        });
      }
      this.setState({ addloading: false });
    });
  }

  onAddToStock() {
    let data = this.formRef.current.getFieldsValue();
    
    this.setState({ addloading: true });
    this.props.onSuccessSubmit(data);
  }

  setLoad(value) {
    this.setState({ addloading: value });
  }

  render() {
    return [
      <Spin spinning={this.state.addloading} key={1}>
        <Form
          {...layout}
          onFinish={this.onAddToStock}
          name="basic"
          ref={this.formRef}
          layout="vertical"
        >
          <Form.Item
            label="Название"
            name="name"
            rules={[{ required: true, message: "Пожалуйста введите название" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Рост"
            name="growth"
            rules={[
              { required: true, message: "Пожалуйста введите рост стебля!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Страна"
            name="country"
            rules={[{ required: true, message: "Пожалуйста введите Страну" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Кратность"
            name="multiplicity"
            rules={[
              { required: true, message: "Пожалуйста введите кратность" },
            ]}
          >
            <InputNumber step={1} />
          </Form.Item>

          <Form.Item
            label="Себестоимость"
            name="selfcost"
            rules={[
              { required: true, message: "Пожалуйста введите себестоимость" },
            ]}
          >
            <InputNumber step={1} />
          </Form.Item>
          <Form.Item
            label="Цена"
            name="price"
            rules={[{ required: true, message: "Пожалуйста введите цену" }]}
          >
            <InputNumber step={1} />
          </Form.Item>

          <Form.Item
            label="Оптово-розничная цена"
            name="WR_PRICE"
            // rules={[{ required: true, message: "Пожалуйста введите оптово-розничную цену" }]}
          >
            <InputNumber step={1} />
          </Form.Item>
          <Form.Item
            label="Доступно"
            name="available"
            rules={[
              { required: true, message: "Пожалуйста введите количество" },
            ]}
          >
            <InputNumber step={1} />
          </Form.Item>
          <Form.Item label="Доп. Характеристики" name="more">
            <TextArea />
          </Form.Item>

          {
           this.props.forTrade && <Form.Item
           label="Торговый день"
           name="tradingday"
           rules={[
             { required: true, message: "Пожалуйста выберите торговый день" },
           ]}
         >
           <Select style={{ width: "100%" }} placeholder="Торговый день">
             {this.state.tradinglist.map((item, i) => {
               return (
                 <Option value={item.id} key={i.toString(36) + i}>
                   {moment(item.date).format('DD MMMM YYYY')}
                 </Option>
               );
             })}
           </Select>
         </Form.Item>
         }

          <Form.Item {...tailLayout}>
            <Input type="submit" style={{ display: "none" }} />
            {/* <Button type="primary" className="loginbtn" htmlType="submit">
                            Добавить
                          </Button> */}
          </Form.Item>

         
          
        </Form>
      </Spin>,
    ];
  }
}

StockEditForm.contextType = AppContext;

export default withRouter(StockEditForm);
