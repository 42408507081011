import * as React from "react";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../helpers/app";
moment.locale("ru");

class MiniTotalComponent extends React.Component {
  constructor(props) {
    super(props);   
    this.state = {price: 0, errors: [],selfdiscount: this.props.selfdiscount};    
  }

  getUserInfo(){
      if(this.props.user.usertype == 'walker'){
          return 'Оптово-розничная цена';
      }else if(this.props.user.usertype == 'user'){
          return 'Оптовая цена';
      }else if(this.props.user.usertype == 'user' && this.props.user.selfdiscount){
          return 'Цена по себестоимости';
      }
  }

  render(){
      return(
          <div className="minitotal__wrapper">
                <h1>{AppHelper.toCurrency(this.state.price, "RUB", "Ru-ru")}</h1>
                <span>{this.getUserInfo()}</span>
                <div className="mintotal__error">
                    {
                        this.state.errors.map((item, index) => {
                            return <span key={index} className="error">{item}</span>
                        })
                    }
                </div>
          </div>        
      );
  }

  componentWillReceiveProps(next){
      this.setState({selfdiscount: next.selfdiscount})
  }

  componentDidMount(){
    this.countCost();
  }

  

  countCost(){ 
    let price = 0;       
    if(this.props.user.usertype == 'walker'){
        if(this.props.products.length > 0 && this.props.products.filter(i => i!= null).length == 0) return;
        let WR_PRICE_IS = true;
        let errors = [];
        if(this.props.products.includes(null)) return;
        this.props.products.map(i => {
            
            if(!i.WR_PRICE){
                WR_PRICE_IS = false;
            }
        })
        if(!WR_PRICE_IS){
            errors.push('Не у всех позиций указана оптоворозничная цена');            
        }
        this.setState({errors: errors})
        if(errors.length > 0) return;
        price = 0;
        this.props.products.map(i => {price += i.WR_PRICE * (i.count)});
        this.setState({price: price})        
    }else if(this.props.user.usertype == 'user'){
        if(this.props.products.filter(i => i!= null).length == 0) return;
        let PRICE_IS = true;
        let errors = [];
        this.props.products.map(i => {
            
            if(!i.price){
                PRICE_IS = false;
            }
        })
        if(!PRICE_IS){
            errors.push('Не у всех позиций указана цена');            
        }
        this.setState({errors: errors})
        if(errors.length > 0) return;
        price = 0;
        //console.log(this.props.products);
        let pricename = 'price';
        if(this.state.selfdiscount){
            pricename = 'selfcost'
        }
        this.props.products.map(i => {price += i[pricename] * (i.count)});
        this.setState({price: price})        
    }

    return price;
    
  }

  
  
}

export default MiniTotalComponent;
