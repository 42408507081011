import React from 'react';
import '../../App.scss';

import AppLayout from '../layout/layout'
import {
    Route,
    Switch,
    Redirect,
    withRouter,
    BrowserRouter
  } from "react-router-dom"
import Stock from '../stock/stock';
import StockEditComponent from '../stock/edit';

import AppContext from "../common/appContext";
import Exchange from '../exchange/exchange';
import Cart from '../cart/cart';
import Account from '../account/account'
import {ReactComponent as CartIcon} from '../../assets/cart.svg';
import {ReactComponent as StockIcon} from '../../assets/warehouse.svg';
import {ReactComponent as ExchangeIcon} from '../../assets/exchange.svg';
import {ReactComponent as ListIcon} from '../../assets/requests.svg';
import {ReactComponent as CatalogIcon} from '../../assets/stock.svg'; // Какая то хуйняяяяя  
import {ReactComponent as DirectoryIcon} from '../../assets/directory.svg';
import {ReactComponent as SpisIcon} from '../../assets/spis.svg';
import {ReactComponent as TransactionOutlined} from '../../assets/lending.svg';
import {ReactComponent as DashboardOuline} from '../../assets/dashboard.svg';
import {ReactComponent as PreorderIcon} from '../../assets/pre.svg';


import NotFound from '../common/notfound';
import SettingsComponent from '../common/settings';
import Catalog from '../catalog/catalog';
import CatalogItemEdit from '../catalog/edit';
import TsTypes from '../directory/tstype/index'
import PaymentType from '../directory/paytype/index'
import CategoryComponent from '../catalog/category/index'
import TradingDaysComponent from '../directory/days'
import DirectoryComponent from '../directory/directory'
import UsersComponent from '../users/index'
import UsersComponentEdit from '../users/edit'
import Organizations from '../organizations/organizations'
import Colors from '../colors/colors'
import AboutComponent from '../about/index'
import ResetPassComponent from '../common/resetpass'
import RequestsComponent from '../requests/index'
import RequestsComponentForAdmin from '../requests/admin'
import RequestsComponentNew from '../requests/v2/index'
import TradingSelectComponent from '../exchange/tradingselect';
import ExchangeEditComponent from '../exchange/edit';
import WriteOffs from '../writeOffs/writeOffs'
import RequestsComponentByUser from '../requests/users';
import UserCart from '../user-cart';
import Transactions from '../transactions/index';
import Dashboard from '../dashboard/index'
import ManagerCart from '../user-cart/manager-cart/index'
import ExcelParser from '../excelparser/index';
import Constans from '../directory/constans/constans';
import SimpleRequest from '../requests/simple';
import WelcomePageComponent from '../welcome/index';
import PreorderEditComponent from '../requests/preorder/edit'
import Calls from '../calls/index'
import PreordersComponent from '../requests/preorder/index'
import RequestsV2 from '../requests/v2';
import ExchangePrivateRouter from './ExchangePrivateRouter'
import {
    SnippetsOutlined
  } from '@ant-design/icons';
import TouchCaneldar from '../users/touchcalendar/touchcalendar';


const router = [
    {path: '/dashboard', label: 'Статистика', icon: <DashboardOuline />, access: ['admin']},        
    {path: '/catalog', label: 'Каталог', icon: <CatalogIcon />, access: ['admin']}, 
    {path: '/exchange', label: 'Биржа', icon: < ExchangeIcon />, access: ['user', 'admin']},
    {path: '/stock', label: 'Склад', icon: < StockIcon />, access: ['user', 'admin']},
    {path: '/requests', label: 'Мои заявки', icon: <ListIcon />, access: ['user']},
    {path: '/writeOffs', label: 'Списания', icon: <SpisIcon />, access: ['admin']},            
    {path: '/usersrequests', label: 'Заявки', icon: <ListIcon />, access: ['admin']},
    {path: '/preorder', label: 'Предзаказы', icon: <PreorderIcon />, access: ['admin','user']},
    {path: '/cart', label: 'Корзина', icon: <CartIcon />, access: ['user']},            
    // {path: '/category', label: 'Категории товаров', icon: <CatalogIcon />, access: ['admin']},    
    // {path: '/users', label: 'Пользователи', icon: <CatalogIcon />, access: ['admin']},        
    {path: '/directory', label: 'Справочники', icon: <DirectoryIcon />, access: ['admin']},    
    {path: '/transactions', label: 'Транзакции', icon: <TransactionOutlined />, access: ['admin']},        
    
    
    
    
]

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
    //console.log(rest)
    return (
    <Route {...rest} render={(props) => (
        auth == 'admin'
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
)}

const PrivateRoute2 = ({ component: Component, auth, ...rest }) => (
    <Route {...rest} render={(props) => (
        auth == 'admin'
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
)


const ExchangeCheckRoute = ({component: Component}) => {
    setTimeout(() => {
        isAccept = true;
    }, 2000)
    let isAccept = false;
    if(isAccept){
        return 'Доступ';
    }else{
        return 'Не доступ';
    }    
    
}



class Root extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        const { history } = this.props
        const path = window.location.pathname;
        return(
            <div id="root_wrapper">
                <AppLayout routes={router}>
                           
                          
                    <Route path="*" exact render={() => {                        
                        return <Redirect to={(path !== '/' && path !== '/login') ? path : '/stock'} />
                    }} />   
                    
                    <Switch>
                        <Route exact history={history}  path='/writeOffs/' render={() => <WriteOffs />} />  
                        <PrivateRoute  path='/colors/' component={Colors} auth={this.context.usertype} />  
                        <PrivateRoute history={history}  path='/organizations/' component={Organizations} auth={this.context.usertype} />          
                        <Route history={history}  path='/account/' render={() => <Account />} /> 
                        <Route exact history={history}  path='/stock/' render={() => <Stock />} />                        
                        <Route exact history={history}  path='/stock/edit/:id' render={() => <StockEditComponent />} />                                         
                        <Route history={history}  path='/exchange/edit/:id' render={() => <ExchangeEditComponent />} />                                                          
                        {/* <Route history={history}  path='/exchange/:day' render={() => <Exchange />} />                                                        */}
                        <ExchangePrivateRouter exact history={history} path="/exchange/:day" component={Exchange}  />
                        {/* <ExchangeCheckRoute path="/exchange/:day" component={Exchange} /> */}
                        <Route history={history}   path='/exchange/' render={() => <TradingSelectComponent />} />                                             
                        
                        <Route history={history}  path='/cart/' render={() => <Cart />} />      
                        <Route history={history}  path='/catalog/edit/:id' render={() => <CatalogItemEdit />} />   
                        <Route history={history}  path='/requests' render={() => <RequestsComponent />} />   
                        <Route history={history}  path='/v2/requests' render={() => <RequestsV2 />} />   
                        <Route history={history}  path='/usersrequests' render={() => <RequestsComponentNew />} />
                        <Route history={history}  path='/usersrequestsold' render={() => <RequestsComponentForAdmin/>} />    
                        <Route history={history}  path='/resetpass' render={() => <ResetPassComponent />} />   
                        
                        <Route history={history}  path='/catalog/' render={() => <Catalog />} /> 
                                        
                        
                        <PrivateRoute path='/directory/category/' component={CategoryComponent} auth={this.context.usertype} />
                        <PrivateRoute path='/directory/tradingdays/' component={TradingDaysComponent} auth={this.context.usertype} />
                        <PrivateRoute path='/directory/settings/' component={SettingsComponent} auth={this.context.usertype} />
                        <PrivateRoute path='/directory/tstypes/' component={TsTypes} auth={this.context.usertype} />
                        <PrivateRoute path='/directory/payment/' component={PaymentType} auth={this.context.usertype} />    


                        // Он тебперь на эту смотри в последнюю очередь, то есть сравнение идет сначала с подразделами
                        <PrivateRoute path='/directory/' component={DirectoryComponent} auth={this.context.usertype} />                                                
                                              
                        
                        <Route history={history} exact  path='/users/:id/requests' render={() => <RequestsComponentByUser />} />              
                        <Route history={history} exact  path='/users/:id' render={() => <UsersComponentEdit />} />                              
                        <PrivateRoute history={history} exact  path='/users/' component={UsersComponent} auth={this.context.usertype} />    
                        <Route history={history} exact  path='/aboutapp/' render={() => <AboutComponent />} />    
                        <Route history={history} exact  path='/profile/:id' render={() => <UserCart />} />    
                        <Route history={history} exact  path='/profile/' render={() => <UserCart />} />   
                        <Route history={history} exact  path='/manager/:id' render={() => <ManagerCart />} />   
                        <Route history={history} exact  path='/transactions/' render={() => <Transactions />} />  
                        <Route history={history} exact  path='/dashboard' render={() => <Dashboard />} />   
                        <Route history={history} exact  path='/excelparser' render={() => <ExcelParser />} />   
                        <PrivateRoute history={history} exact  path='/const' component={Constans} auth={this.context.usertype} />   
                        <Route history={history} exact  path='/preorder/' render={() => <PreordersComponent />} />   
                        <Route history={history} exact  path='/preorder/:id' render={() => <PreorderEditComponent />} />   
                        <Route history={history} exact  path='/simplerequest' render={() => <SimpleRequest />} />   
                        <Route history={history} exact  path='/touchcalendar' render={() => <TouchCaneldar />} /> 
                        <Route history={history} exact  path='/calls' render={() => <Calls />} />   
                        
                                                 
                                               
                        <Route history={history} path="/notfound"  render={() => <NotFound />} />    
                        <Route exact history={history} path="/"  render={() => {
                            
                            if(this.context.usertype == 'admin'){
                                return <Redirect to={'/dashboard'} />
                            }else{
                                return <Redirect to={'/stock'} />
                            }
                            
                        }} />          
                        <Route exact history={history} path="/login"  render={() => <Redirect to={'/stock'} />} />          
                        <Route exact history={history}  render={() => {return <Redirect to='/notfound' />}} />                        
                    </Switch>   
                               
                </AppLayout>                
            </div>
        )
    }
}


Root.contextType = AppContext;

export default withRouter(Root);
