import * as React from "react";
import {
  Breadcrumb,
  Skeleton,
  Card,
  Row,
  Col,
  Button,
  PageHeader,
  Spin,
  message,
  Modal,
  Form,
  Typography
} from "antd";
import { Alert } from 'antd';
import { Select } from "antd";
import { NavLink } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AppHelper from "../../helpers/app";
import rest from "../../helpers/rest";
import UserSelectModalComponent from "../users/userselectmodal";
import PayTypeMulti from './multipaytype/mpaytype'
import ProductSelectorComponent from "../catalog/productselector/productselector";
import MiniProductComponent from "./miniproduct/miniproduct";
import MiniTotalComponent from "./miniproduct/minitotal";
import AppContext from "../../components/common/appContext";
import { withRouter } from 'react-router-dom';
import * as moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;

class SimpleRequest extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Быстрая заявка", key: "/" },
    ];
    this.state = {
      price__type: { label: "Оптово-розничная", value: "WR" },
      usertype: "walker",
      userSelectVisible: false,
      userid: false,
      userinfoloading: false,
      userinfo: null,
      positions: [],
      selfdiscount: false,
      loadedCount: 0,
      payTypes: [],
      requestToFiscal: null,
      totalSum: 0
    };
    this.renderUserForm = this.renderUserForm.bind(this);
    this.checkNeedFiscal = this.checkNeedFiscal.bind(this)
    this.showConfirmFiscal = this.showConfirmFiscal.bind(this);
    this.selectUserFromModal = this.selectUserFromModal.bind(this);
    this.changeUserType = this.changeUserType.bind(this);
    this.addNewPosition = this.addNewPosition.bind(this);
    this.removePosition = this.removePosition.bind(this);
    this.totalRef = React.createRef();
    this.submitRequest = this.submitRequest.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.payTypeFormRef = React.createRef();
    this.payMethodsRef = React.createRef();
  }


  componentDidMount(){
    rest.get('/api/Transactions/paytypes').then(data => {      
      this.setState({payTypes: data.data.data}, () => {
        //console.log(data.data.data[0].id)
        if(data.data.data.length > 0){
          //this.payTypeFormRef.current.setFieldsValue({pay__type: data.data.data[0].id})                    
        }        
      });
    })
  }

  renderUserForm() {
    return (
      <div className="userinfo__wrapper">
        <Button
          onClick={() => this.setState({ userSelectVisible: true })}
          className="user__select_btn"
          type="primary"
        >
          Выбрать пользователя
        </Button>
        <Spin spinning={this.state.userinfoloading}>
          {this.state.userid && (
            <div className="userinfo__data">
              {this.state.userinfo && (
                <div className="user__params">
                  <span className="fio">{`${this.state.userinfo.fname} ${this.state.userinfo.sname}`}</span>
                  <span className="login">{`@${this.state.userinfo.login}`}</span>
                  <span className="phone">{`${this.state.userinfo.phone}`}</span>
                  <span className="regdate">
                    <span className="label">Дата регистрации:</span>
                    {`${moment(this.state.userinfo.regdate).format(
                      "DD MMMM YYYY"
                    )}`}
                  </span>
                </div>
              )}
            </div>
          )}
        </Spin>
      </div>
    );
  }

  selectUserFromModal(id) {
    this.setState({
      userSelectVisible: false,
      userid: id,
      userinfoloading: true,
    });
    rest.get("/api/Account", { userid: id }).then((data) => {
      if (data.data.data.length == 0) {
        message.error("Ошибка, пользователь не найден");
        this.setState({ userid: false, userinfoloading: false });
      } else {
        this.setState({ userinfo: data.data.data[0], userinfoloading: false });
        let selfdiscount =
          data.data.data[0].discount &&
          data.data.data[0].discount.includes("selfcost");
        this.setState({ selfdiscount: selfdiscount }, () => {
          this.totalRef.current.countCost();
          this.setState({totalSum: this.totalRef.current.countCost()});
        });
      }
    });
  }

  addNewPosition(id) {
    // Проверяем, может быть уже есть такая позиция    
    let found = this.state.positions.filter((i) => i.id == id);
    if (found.length > 0) {
      // Такая позиция есть
      if (found[0].ref.current.getLoading()) {
        message.error("Дождитесь загрузки позиции");
      } else {
        found[0].ref.current.addOneMultiplicity();
        this.setState({ positions: this.state.positions }, () => {});
        this.totalRef.current.countCost();
        //console.log(this.totalRef.current.countCost())
        this.setState({totalSum: this.totalRef.current.countCost()});
      }
    } else {            
      let positions = this.state.positions;
      positions.push({ id: id, ref: React.createRef() });      
      this.setState({ positions: positions });      
      this.totalRef.current.countCost();  
      this.setState({totalSum: this.totalRef.current.countCost()});
      //console.log(this.totalRef.current.countCost())
    }
  }

  fiscalize(id, type){
    this.setState({loading: true, fiscalemodal: false})
    rest.post('/api/Requests/addtofiscale', {id:id, fiscaletype: type || 1}).then(data => {
      message.success('Чек отправлен на фискализацию')
      this.setState({loading: false});      
    })
  }

  updateCount(id) {
    let pos = this.state.positions.filter((i) => i.id == id)[0];
    pos.count = pos.ref.current.getProductData().count;
    let positions = this.state.positions;
    positions.map((item, index) => {
      if (item.id == id) {
        positions[index] = item;
      }
    });
    this.setState({ positions: positions });
    setTimeout(() => {
      this.totalRef.current.countCost();
      this.setState({totalSum: this.totalRef.current.countCost()});
    }, 100);
  }

  changeUserType(type) {
    if (type == "user" && this.state.usertype == "user") return;
    let pt = { label: "Оптово розничная", value: "WR" };
    if (type == "user") {
      pt = { label: "Оптовая", value: "OPT" };
    }
    this.setState(
      {
        usertype: type,
        userinfo: null,
        userid: false,
        price__type: pt,
        selfdiscount: false,
      },
      () => {
        this.totalRef.current.countCost();
        this.setState({totalSum: this.totalRef.current.countCost()});
      }
    );
  }

  removePosition(id) {
    let pos = this.state.positions.filter((i) => i.id != id);
    this.setState({ positions: pos }, () => {
      setTimeout(() => {
        this.totalRef.current.countCost();
        this.setState({totalSum: this.totalRef.current.countCost()});
      }, 100);
    });
    this.setState({ loadedCount: this.state.loadedCount - 1 });
  }

  // Подтверждение заявки
  async submitRequest() {
    
    // Если выбран тип пользователя "Пользователь", но не выбран конкретный пользователь - лесом
    if (
      !this.state.userinfo &&
      !this.state.userid &&
      this.state.usertype == "user"
    ) {
      message.error("Выберите пользователя!");
      return;
    }
    let paytype = await this.payMethodsRef.current.validPayForms();

    
    //let paytype = false;
    //let paytype = this.payTypeFormRef.current.getFieldValue().pay__type;
    if(!paytype){
      message.error("Выберите тип оплаты");
      return;
    
    }    
    
    
    let valid = true;
    this.state.positions.map((i) => {
      if (!i.ref.current.validate()) {
        valid = false;
      }
    });
    if (!valid) {
      message.error("Ошибка в заказе");
    } else {
      // Отправляем запрос на создание заявки из быстрого режима
      let positions = [];
      this.state.positions.map((i) => {
        positions.push(i.ref.current.getProductData());
      });
      let dataToSend = {
        user: {
          userinfo: this.state.userinfo,
          type: this.state.usertype,
          id: this.state.userid,
        },
        positions: positions,
        total: this.totalRef.current.countCost(),
        paytype: paytype
      };
      this.context.setLoad(true, "Создание быстрой заявки");
      rest
        .post("/api/Requests/fastrequest", dataToSend)
        .then((data) => {
          this.context.setLoad(false);
          //AppHelper.autocloseModal("Заявка создана", 5);
          this.resetAll();
          //console.log(data.data.data.detail.insertId)
          this.setState({requestToFiscal: data.data.data.detail.insertId});
          this.checkNeedFiscal();
        })
        .catch((err) => {
          this.context.setLoad(false);
          message.error(err.response.data.data.detail);
        });
      // setTimeout(() => {
      //   this.context.setLoad(false, 'Создание быстрой заявки');
      // }, 3000)
    }
  }


  checkNeedFiscal(){
    if(this.state.requestToFiscal != null){
      // Значит спрашиваем пользователя, нужно ли фискализировать чек?
      this.showConfirmFiscal()
    }
  }

  showConfirmFiscal() {
    this.setState({fiscalemodal: true})
    // confirm({
    //   title: 'Фискализировать чек ?',
    //   icon: <ExclamationCircleOutlined />,
    //   content: `Заявка № ${this.state.requestToFiscal}`,
      
    //   onOk: () => {
    //     rest.post('/api/Requests/addtofiscale', {id:this.state.requestToFiscal}).then(data => {
    //       message.success('Чек отправлен на фискализацию')
    //       this.setState({loading: false})
    //     })
    //   },
    //   onCancel() {
    //     console.log('Cancel');
    //   },
    // });
  }

  // Функция сбрасывает все в окне
  resetAll() {
    this.changeUserType("walker");
    this.setState({ positions: [], loadedCount: 0 });
    this.totalRef.current.countCost();
    this.setState({totalSum: this.totalRef.current.countCost()});
  }

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card" style={{ padding: "20px 0px" }}>
        <Row key={4}>
          <Col span={this.state.cataspan} style={{ width: "100%" }}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Быстрая заявка"
              extra={[
                <Button key={1} onClick={() => this.resetAll()}>
                  Сбросить
                </Button>,
              ]}
            />
          </Col>
        </Row>
        <Row >
          <Col md={7} xs={24}>
            <div className="simple__request__userinfo">
              <div className="simple__request__userinfo__top">
                <h3>Пользователь</h3>
              </div>
              <div className="simple__request__userinfo__type__switcher">
                <div
                  onClick={() => this.changeUserType("walker")}
                  className={`simple__request__userinfo__type__item ${
                    this.state.usertype == "walker" ? "active" : ""
                  }`}
                >
                  Прохожий
                </div>
                <div
                  onClick={() => this.changeUserType("user")}
                  className={`simple__request__userinfo__type__item ${
                    this.state.usertype == "user" ? "active" : ""
                  }`}
                >
                  Пользователь
                </div>
              </div>
              {this.state.usertype == "user" && this.renderUserForm()}
              {
                <Spin spinning={this.state.payTypes.length == 0}>
                <div className="payment__type">
                  <PayTypeMulti ref={this.payMethodsRef} total={this.state.totalSum} />
                  {/* <Form
                    name="paytype__form"
                    className="paytype__form"
                    ref={this.payTypeFormRef}
                    layout="vertical"
                  >
                    <Form.Item
                      label="Тип оплаты"
                      name="pay__type"
                    >
                      <Select>
                        {                          
                          this.state.payTypes.map((i, index) => <Option key={index} value={i.id}>{i.name}</Option>)
                        }
                      </Select>                      
                    </Form.Item>
                  </Form> */}
                </div>
                </Spin>
              }
            </div>
          </Col>
          <Col md={17} xs={24}>
            <div className="simple__request__positions">
              <div className="simple__request__positions__top">
                <h3>Позиции</h3>
                <div className="right__block">
                  <div className="price__type_wrapper">
                    <div className="price__type">
                      <span className="price__type_label">Цена: </span>
                      <span className="price__type_value">
                        {this.state.price__type.label}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="simple__request__selector">
                <ProductSelectorComponent
                  onSelect={(id) => {                    
                    this.addNewPosition(id);                    
                  }}
                />
              </div>
              <div className="simple__request__total">
                <MiniTotalComponent
                  ref={this.totalRef}
                  selfdiscount={this.state.selfdiscount}
                  products={this.state.positions.map((i) => {
                    return i.ref.current
                      ? i.ref.current.getProductData()
                      : null;
                  })}
                  user={{
                    usertype: this.state.usertype,
                    userid: this.state.userid,
                  }}
                />
              </div>
              <div
                className={`simple__request__positions__list ${
                  this.state.positions.length == 0 ? "none" : ""
                }`}
              >
                {this.state.positions.map((position, i) => {
                  return (
                    <MiniProductComponent
                      ref={position.ref}
                      onChange={(id) => {
                        this.setState({ position: this.state.positions });
                        this.totalRef.current.countCost();
                        this.setState({totalSum: this.totalRef.current.countCost()});
                        this.updateCount(id);
                      }}
                      onRemove={(id) => this.removePosition(id)}
                      index={i}
                      onLoad={() => {
                        this.setState({
                          loadedCount: this.state.loadedCount + 1,
                        });
                      }}
                      className="simple__request__positions__item__component"
                      type="Stock"
                      key={position.id}
                      id={position.id}
                    />
                  );
                })}
              </div>
              {this.state.positions.length > 0 &&
                this.state.loadedCount == this.state.positions.length && (
                  <div className="simple__request__submit">
                    <Button
                      className="submit__btn"
                      type="primary"
                      onClick={() => this.submitRequest()}
                    >
                      Подтвердить
                    </Button>
                  </div>
                )}
            </div>
            ,
          </Col>
        </Row>
      </Card>,
      <UserSelectModalComponent
        onSelect={(id) => this.selectUserFromModal(id)}
        key={3}
        onClose={() => this.setState({ userSelectVisible: false })}
        visible={this.state.userSelectVisible}
      />,
      <Modal
       visible={this.state.fiscalemodal}
       title="Тип оплаты"       
       onCancel={() => this.setState({fiscalemodal: false, tofiscaleid: null})}
       footer={[          
          <Button key="back" type="primary" onClick={() => this.fiscalize(this.state.requestToFiscal, 1)}>Фискализировать</Button>,          
          <Button key="back" onClick={() => this.setState({fiscalemodal: false, tofiscaleid: null})}>Отмена</Button>
       ]}
     >
       <p>Выберите тип оплаты для фискализации</p>       
       <Alert message="Внимание! Оплата переводом на карту не фискализируется" type="warning" />
     </Modal>
    ];
  }
}

SimpleRequest.contextType = AppContext;

export default withRouter(SimpleRequest);
