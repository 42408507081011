import * as React from 'react';
import {Select, Form } from 'antd';
import rest from '../../helpers/rest'
const { Option } = Select;

class ColorSelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colorlist: [],
            colorlistresult: [],
        };
        this.searchColor = this.searchColor.bind(this);
    }

    componentWillMount(){
        rest.get('/api/Attributes', {type: 'color', all: this.props.all || false}).then(colors => {            
            this.setState({colorlist: colors.data.data, colorlistresult: colors.data.data})
        })
    }

    searchColor(e){
        this.setState({colorlistresult: this.state.colorlist.filter(item => item.name.includes(e))})
    }

    render() {        
        return(
            <Form.Item
                            label="Цвет"
                            name="color" 
                            rules={[
                                { required: this.props.required || false, message: "Пожалуйста выберите цвет" },
                              ]}                                                                          
                        >
            <Select
                        mode={this.props.mode || 'multiple'}                        
                        style={{ width: '100%' }}
                        placeholder="Выберите цвет"
                        tokenSeparators={[',']}                        
                        filterOption={false}
                        onSearch={this.searchColor}                        
                        
                      >
                        {this.state.colorlistresult.map((item, i) => {
                            return <Option label={item.name} value={item.id} key={i.toString(36) + i}>
                                <div className="colorselectitem">
                                <div className="colordisplay" style={{background: item.value}}></div><span>{item.name} </span>
                                 </div>
                            </Option>
                        })}
                      </Select>
                      </Form.Item>
        );
    }
}

export default ColorSelectComponent;
