import React from "react";
import "../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../components/common/appContext";
import Breadcrumb from "../common/breadcrumb";

import {
  Spin,
  Card,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Skeleton,
  Modal,
  message,
  InputNumber,
  Table,
  Tag,
  Popconfirm,
} from "antd";
import { Radio, Space } from "antd";
import {
  PageHeader,
  Menu,
  Dropdown,
  Button,
  Tabs,
  DatePicker,
  Select,
} from "antd";
import rest from "../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../helpers/app";
import BalanceHistory from "../user-cart/balancehistory/index";
import TransactionTypeSelector from "../transactions/transationtype-selector/index";
import TransactionFiles from "../transactions/files/index";
import locale from "antd/es/date-picker/locale/ru_RU";
import {
  MenuOutlined,
  FileTextOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
moment.locale("ru");
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.filterAction = this.filterAction.bind(this);
    this.formFilterRef = React.createRef();
    this.resetFilter = this.resetFilter.bind(this);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Транзакции", key: "/" },
    ];

    this.columns = [
      {
        title: "Дата",
        key: "date",
        render: (text, record) => {
          return moment(record.date).format("DD-MM-YYYY HH:mm");
        },
      },
      {
        title: "Сумма",
        key: "summ",
        render: (text, record) => {
          return (
            <Tag color={record.summ > 0 ? "green" : "red"}>
              {AppHelper.toCurrency(record.summ, "RUB", "Ru-ru")}
            </Tag>
          );
        },
      },
      {
        title: "Тип",
        dataIndex: "typename",
        key: "typename",
      },
      {
        title: "Способ оплаты",
        key: "payment",
        render: (text, record) => {
          return record.payname;
        },
      },
      {
        title: "Заявка",
        key: "request",
        render: (text, record) => {
          return record.request;
        },
      },
      {
        title: "Пользователь",
        key: "userid",
        render: (text, record) => {
          return record.userid ? (
            <NavLink to={`/profile/${record.userid}`}>
              <Button style={{ width: "auto" }}>
                Карточка пользователя {record.login}
              </Button>
            </NavLink>
          ) : (
            ""
          );
        },
      },
      {
        title: 'Комментарий',
        key: 'comment',
        dataIndex: 'comment'
      },
      {
        title: "Вложения",
        key: "attachment",
        render: (text, record) => (
          <a
            onClick={() =>
              this.setState({ fileopen: true, transactionfileid: record.id })
            }
          >
            <FileTextOutlined
              style={{ color: record.files === 0 ? "black" : "" }}
            />
          </a>
        ),
      },
      {
        title: "Действия",
        render: (text, record) => {
          return (
            <Popconfirm
              title="Вы действительно хотите удалить эту транзакцию?"
              onConfirm={() => this.removeTransaction(record.id)}
              okText="Да"
              cancelText="Нет"
            >
              <DeleteOutlined />
            </Popconfirm>
          );
        },
      },
    ];

    this.state = {
      transactionAddLoading: false,
      addTransactionsModalVisible: false,
      filterParams: {},
      tableData: [],
      fileopen: false,
      transactionfileid: 0,
      loading: false,
      paytypes: []
    };
    this.openAddTransactionModal = this.openAddTransactionModal.bind(this);
    this.formTransactionAddRef = React.createRef();
    this.onSubmitAddTransaction = this.onSubmitAddTransaction.bind(this);
    this.onSubmitAddTransactionPre = this.onSubmitAddTransactionPre.bind(this);
    this.removeTransaction = this.removeTransaction.bind(this);
  }

  componentDidMount() {
    document.title = "Транзакции | Букетная.ОПТ";
    this.formFilterRef.current.setFieldsValue({
      date: [moment(new Date()), moment(new Date())],
    });

    
    this.filterAction();
    rest.get('/api/Transactions/paytypes').then(data => {
      this.setState({paytypes: data.data.data})
    })
  }

  removeTransaction(id) {
    
    this.setState({ loading: true });
    rest.delete("/api/Transactions/", { id: id }).then((data) => {
      this.filterAction();
    });
  }

  filterAction() {
    
    this.setState({ loading: true });
    let params = this.formFilterRef.current.getFieldsValue();
    //console.log(params.date)
    let paramstopush = {};
    Object.keys(params).map((i) => {
      if (params[i] && i != "date") {
        paramstopush[i] = params[i];
      }
    });
    if (params.date) {
      paramstopush.start = moment(params.date[0])        
        .format("YYYY-MM-DD");
      paramstopush.end = moment(params.date[1])
        //.add(1, "day")
        .format("YYYY-MM-DD");
    }
    this.setState({ filterParams: paramstopush }, () => {
      
      rest.get("/api/Transactions", this.state.filterParams).then((data) => {
        this.setState({ tableData: data.data.data, loading: false });
      });
    });
  }
  resetFilter() {
    this.setState({ filterParams: {} });
    this.formFilterRef.current.resetFields();
    this.formFilterRef.current.setFieldsValue({
      date: [moment(new Date()).subtract("1", "day"), moment(new Date())],
    });
    this.filterAction();
  }

  openAddTransactionModal() {
    this.setState({ addTransactionsModalVisible: true });
  }

  onSubmitAddTransactionPre() {
    this.formTransactionAddRef.current.submit();
  }

  onSubmitAddTransaction() {
    this.setState({ transactionAddLoading: true });
    let values = this.formTransactionAddRef.current.getFieldsValue();
    rest.post("/api/Transactions/create", { ...values }).then((data) => {
      this.setState({
        addTransactionsModalVisible: false,
        loading: true,
        transactionAddLoading: false,
      });
      this.filterAction();
      let values = this.formTransactionAddRef.current.resetFields();
    });
    //console.log(this.formTransactionAddRef.current.getFieldsValue());
  }

  onChangeTime = (e) => {
    this.setState({ value: e.target.value });
    let dates = [moment(new Date()), moment(new Date())]
    console.log(e.target.value);
    switch(e.target.value){
      case 1 : {
        dates = [
          moment(new Date()).startOf("month").subtract(1, "month"),
          moment(new Date()).endOf("month").subtract(1, "month"),
        ]
        break;
      }
      case 2 : {        
        dates = [
          moment(new Date()).startOf("month"),
          moment(new Date()),
        ]
        break;
      }
      case 3 : {
        //break;
        dates = [
          moment(new Date()).subtract("30", "days"),
          moment(new Date()),
        ]
        break;
      }
      case 4 : {
        dates = [moment(new Date()), moment(new Date())]
        break;
      }
    }
    this.formFilterRef.current.setFieldsValue({date: dates});
  }

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <TransactionFiles
        open={this.state.fileopen}
        id={this.state.transactionfileid}
        close={() => {
          this.setState({ fileopen: false });
        }}
        key={2}
      />,
      <Col key={3} span={this.state.cataspan}>
        <Card className="content-card">
          <PageHeader
            className="site-page-header"
            onBack={() => this.props.history.goBack()}
            title="Транзакции"
            extra={[
              <Button
                key={1}
                onClick={() => this.openAddTransactionModal()}
                type="primary"
              >
                Добавить запись
              </Button>,
              <Modal
                key={2}
                title="Добавить транзакцию"
                onOk={() => this.onSubmitAddTransactionPre()}
                onCancel={() =>
                  this.setState({ addTransactionsModalVisible: false })
                }
                visible={this.state.addTransactionsModalVisible}
              >
                <Spin spinning={this.state.transactionAddLoading}>
                  <Form
                    onFinish={this.onSubmitAddTransaction}
                    onFinishFailed={() => message.warn("Заполните данные")}
                    name="basic"
                    className="transactionaddform"
                    ref={this.formTransactionAddRef}
                    layout="vertical"
                  >
                    <Form.Item
                      label="Сумма операции"
                      name="summ"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста введите сумму",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                    <TransactionTypeSelector required={true} notusers={true} />
                    <Form.Item
                      label="Способ оплаты"
                      name="payment"
                      rules={[
                        {
                          required: this.props.required || false,
                          message: "Выберите способ оплаты",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Выберите способ"
                        loading={this.state.loading}
                      >
                        {this.state.paytypes.map((item, i) => {
                          return (
                            <Option
                              label={item.name}
                              value={item.id}
                              key={i.toString(36) + i}
                            >
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Комментарий"
                      name="comment"                      
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Form>
                </Spin>
              </Modal>,
            ]}
          />
          <Row>
            <Col span={24} >
              <div className="usersrequestfilter">
                <Card className="content-card">
                  <Form
                    name="filter"
                    layout="vertical"
                    ref={this.formFilterRef}
                    initialValues={{ remember: true }}
                    onFinish={() => this.filterAction()}
                    initialValues={{radio: 4}}
                  >
                    <TransactionTypeSelector />

                    <div className="radiowrap" style={{width: 'fit-content'}}>
                    <Form.Item name="radio">
                        <Radio.Group
                          optionType="button"
                          buttonStyle="solid"
                          onChange={this.onChangeTime}
                          value={4}
                        >
                          <Radio.Button value={1} checked="false">
                            Предыдущий месяц
                          </Radio.Button>
                          <Radio.Button value={2} checked="false">
                            Текущий месяц
                          </Radio.Button>
                          <Radio.Button value={3} checked="false">
                            Последние 30 дней
                          </Radio.Button>
                          <Radio.Button value={4} checked="true">
                            Сегодня
                          </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    </div>
                    
                    <Form.Item name="date">
                        <RangePicker style={{ width: "100%" }} locale={locale} format='DD-MM-YYYY' />
                    </Form.Item>

                    <Form.Item name="sumtype">
                      <div className="radiowrap" style={{width: 'fit-content'}}>
                        <Radio.Group buttonStyle="solid" defaultValue="all">                        
                            <Radio.Button value='all'>Все</Radio.Button>
                            <Radio.Button value='plus'>Положительные</Radio.Button>
                            <Radio.Button value='minus'>Отрицательные</Radio.Button>                                             
                        </Radio.Group>
                      </div>
                    </Form.Item>

                    <Button className="filterbtn" onClick={this.resetFilter}>
                      Сбросить фильтр
                    </Button>
                    <Button className="filterbtn" htmlType="submit">
                      Фильтровать
                    </Button>
                  </Form>
                </Card>
                <div style={{ overflowX: "scroll" }}>
                  <Spin spinning={this.state.loading}>
                    <Table
                      rowClassName={(record, index) => record.NOT_STATICTIC === 1 ? 'grey' : record.summ >= 0 ? 'green' : 'red' }
                      dataSource={this.state.tableData}
                      columns={this.columns}
                    />
                  </Spin>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>,
    ];
  }
}

Transactions.contextType = AppContext;

export default withRouter(Transactions);
