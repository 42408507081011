import React from "react";
import "../../App.scss";
import { Button, Card, Spin, PageHeader, Empty } from "antd";
import AppContext from "../../components/common/appContext";
import { withRouter } from "react-router-dom";
import { ReactComponent as LogoFront } from "../../assets/logofront.svg";
import { Layout, Menu, Breadcrumb } from "antd";
import SingleGoodComponent from "../catalog/singlegood";
import rest from "../../helpers/rest";
import { NavLink } from "react-router-dom";
const { Header, Content, Footer } = Layout;

class WelcomePageCompnent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockList: [],
      loading: true,
    };
    rest.get("/api/Stock/getforwr", {}).then((data) => {
      this.setState({
        stockList: data.data.data,
        loading: false,
      });
    });
  }

  render() {
    return (
      <div className="welcome__page">
        <Layout className="layout l-welcome">
          <Header>
            <div className="logo">
              <LogoFront />
            </div>
            <NavLink to="/login">
              <Button type="primary">Вход</Button>
            </NavLink>
          </Header>
          <Content style={{ padding: "0 20px" }}>
            <div className="page__header_frontstock">
              <h1>Склад оптово-розничного центра "Букетная"</h1>
              <h3>
                Указана <span>мелко-оптовая</span> цена, для отображения{" "}
                <span>оптовой</span> цены необходимо{" "}
                <NavLink to="login">авторизоваться</NavLink>
              </h3>
            </div>
            <div className="site-layout-content-welcome">
              <Spin spinning={this.state.loading}>
                <div className="stocklist">
                  {this.state.stockList.map((item, index) => {
                    return (
                      <SingleGoodComponent
                        hideActions={true}
                        onSelect={(data) => this.props.onSelect(data)}
                        selftcostdiscount={
                          (this.context.userdiscount || []).filter(
                            (i) => i.DISCOUNT == "selfcost"
                          ).length > 0
                        }
                        type="stock"
                        refreshList={() => this.getStock()}
                        fromStock={true}
                        setLoad={() => this.setState({ loading: true })}
                        onDelete={() => this.filterAction(false)}
                        WRPRICE_ONLY={true}
                        data={item}
                        key={index}
                      />
                    );
                  })}
                </div>
              </Spin>
              {
              (!this.state.loading && this.state.stockList.length == 0) && 
                <Empty                                    
                  description={
                    <span>
                      Склад Букетная.ОПТ пуст :-(
                    </span>
                  }
                >                  
                </Empty>
              }
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

WelcomePageCompnent.contextType = AppContext;

export default withRouter(WelcomePageCompnent);
