import * as React from 'react';
import { Breadcrumb, Skeleton, Spin, Alert, Popconfirm,  } from 'antd';
import { EyeOutlined, EditOutlined,DeleteOutlined } from "@ant-design/icons";
import { NavLink } from 'react-router-dom';
import AppHelper from '../../helpers/app'
import rest from '../../helpers/rest';

class TotalPrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading: true, total: 0, noDiscount: null, showDiscount: false, discount: [], requestDiscount: null};
        this.getPrice = this.getPrice.bind(this);
    }

    componentWillMount(){
            this.getPrice();
    }

    getPrice(){
        this.setState({loading: true})
        rest.get('/api/Requests/total', {type: this.props.type, requestid: this.props.id}).then(data => {
            let total = data.data.data.total;
            let noDiscount = data.data.data.total
            if(data.data.data.discount.length > 0){
                this.setState({discount: data.data.data.discount})
                data.data.data.discount.map(i => {total -= i.sum;})
            }
            console.log(data.data.data);
            this.setState({requestDiscount: data.data.data.requestDiscount, loading: false, total: total, discoutn: data.data.data.discount || [], noDiscount: noDiscount, showDiscount: total != noDiscount, delivery: data.data.data.delivery})
        })
    }

    componentDidMount(){
        this.props.onRef(this)
    }

    componentWillUnmount(){
        this.props.onRef(undefined)
    }

    deleteDelivery(id){
        console.log(id);
        this.setState({loading: true})
        rest.delete('/api/Requests/deletedelivery', {id:id}).then(data => {      
          this.setState({loading: false, delivery:''})
          this.props.updateRequests();
          console.log(this.props)
        })
    }


    render() {        
        return(
        <Spin spinning={this.state.loading}>
        <div className={'totalprice__block' + this.props.nopadding ? '' : 'p10' + '' + this.props.small ? 'small' : ''} style={{padding : this.props.nopadding ? '0px 10px' : '0px'}}>
            {this.state.loading ? <Skeleton key={1} paragraph={{ rows: 0 }} /> : this.props.small ? <span>Итого: {AppHelper.toCurrency(this.state.total,"RUB","Ru-ru") }</span> :<div> <h1 style={{marginBottom: '0px'}}>
                Итого: {AppHelper.toCurrency(this.state.total,"RUB","Ru-ru")}</h1>{this.state.delivery}
                {this.state.delivery ?
                <Popconfirm
                    title={`Удалить доставку?`}
                    onConfirm={() => this.deleteDelivery(this.props.id)}
                    okText="Да"
                    cancelText="Нет"
                >
              <a style={{ color: "red", marginLeft: '10px' }}>
                <DeleteOutlined />
              </a>
            </Popconfirm> : ''}</div> }
            {this.state.showDiscount && <div key={2} className="discount">Без учета скидки пользователя: <span style={{color: 'red'}}>{AppHelper.toCurrency(this.state.noDiscount   ,"RUB","Ru-ru")}</span></div>}
            {this.state.showDiscount && <div key={3} className="discountLIstInfo">
                {
                    this.state.discount.map((i, index) => {
                        return <span key={10+index}>{AppHelper.discountArray().filter(da => da.value == i.type)[0].name}</span>
                    })
                }
            </div>}
        </div>
        {this.state.requestDiscount && <Alert style={{marginTop: '10px'}} message={`Внимание! к итоговой сумме заявке применена скидка ${this.state.requestDiscount}%`} type="warning" /> }
        </Spin>
            
            
        );
    }
}

export default TotalPrice;
