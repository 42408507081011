import * as React from "react";
import { Select, Form } from "antd";
import rest from "../../helpers/rest";
const { Option } = Select;

class CategorySelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      categoryListResult: [],
    };
    this.searchCategory = this.searchCategory.bind(this);
  }

  componentWillMount() {
    let params = {};
    if(this.props.type){
        params.type = this.props.type;
        params.tradingday = this.props.tradingday;
    }
    rest.get("/api/Category", params).then((category) => {
      this.setState({
        categoryList: category.data.data.list,
        categoryListResult: category.data.data.list,
        categoryloading: false,
      });
    });
  }

  searchCategory(e) {
    //console.log(e);
    //console.log(this.state.categoryList.filter((item) => item.name.includes(e)))
    this.setState({
      categoryListResult: this.state.categoryList.filter((item) =>
        item.name.toLowerCase().includes(e.toLowerCase())
      ),
    });
  }

  render() {
    return (
      <Form.Item
        label="Категориии"
        name="category"
        rules={[
          {
            required: this.props.required,
            message: "Пожалуйста выберите категорию",
          },
        ]}
      >
        <Select
         showSearch={true}
          mode={this.props.mode || "multiple"}
          allowClear
          style={{ width: "100%" }}
          placeholder="Выберите категорию"
          optionFilterProp="children"
          onSearch={this.searchCategory}
          filterOption={(input, option) =>  
            {
              //console.log(option.children[0].toLowerCase(), input.toLowerCase())
              return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          }
        >
          {this.state.categoryListResult.map((item, i) => {
            return (
              <Option value={item.id} key={i.toString(36) + i}>
                {item.name} {(item.total !== 0 && item.total !== 'undefined' && item.total !== undefined && item.total !== null) ? `(${item.total})` : null}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default CategorySelectComponent;
