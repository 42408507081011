import React from "react";
import "../../App.scss";
import { Spin } from "antd";

import { Button} from "antd";
import AppContext from "../../components/common/appContext";

import rest from "../../helpers/rest";
import { withRouter } from "react-router-dom";
import SingleGoodComponent from '../catalog/singlegood'
import TotalPrice from "./totalprice";
import RequestCommentsComponent from "../comments";

class SingleRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cataspan: 24,
      positions: [],
      loading: true,
      selftcostdiscount: false,
      percentdiscount: null
    };    
    this.getPositions = this.getPositions.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.getPercentDiscount = this.getPercentDiscount.bind(this);
    this.childTotal = React.createRef();
  }


  componentWillMount(){
      
      this.getPositions();
  }


  getPositions(){
    rest.get('/api/Cart/getbyrequest', {type: this.props.data.type, request: this.props.data.id}).then(data => {        
        this.setState({
          positions: data.data.data.cart, 
          loading: false, 
          selftcostdiscount: data.data.data.selftcostdiscount,
          percentdiscount: (data.data.data.percentdiscount && data.data.data.percentdiscount.length > 0) ? data.data.data.percentdiscount[0] : null
        });        
    })
  }

  submitRequest(){
    
    this.setState({loading: true})
    rest.update('/api/Requests/changestatus', {requestid: this.props.data.id, status: 'done'}).then(data => {
        //this.setState({count: this.})
        //location.reload();
        window.location.reload();
        this.setState({loading: false})
        //this.getRequests();
        this.props.updateRequests();
    })
}

getPercentDiscount(){    
  if(this.state.percentdiscount && this.state.percentdiscount.DISCOUNT.match(/\d+/g)){
    return parseInt(this.state.percentdiscount.DISCOUNT.match(/\d+/g)[0] || 0)
  }
  return null;
}


  render() {
    
    return (
      <Spin spinning={this.state.loading}>
        <TotalPrice onRef={ref => (this.child = ref)} type={this.props.data.type} id={this.props.data.id} updateRequests  ={this.props.updateRequests}/>
        <div className="stocklist">
        {
        this.state.positions.map((item, index) => {
          
            return <SingleGoodComponent percent={this.getPercentDiscount()} selftcostdiscount={this.state.selftcostdiscount} update={() => {
              this.child.getPrice();
              if(typeof  this.props.update === 'function'){
                this.props.update()
              }
            }} 
            requestid={this.props.data.id} 
            tradingday={this.props.data.tradingday}  
            throwadd={this.props.data.status !== 'active'} 
            incart={'count'} 
            blockMinus={(this.props.data.status === 'done' || this.props.data.status === 'cancel' || this.props.data.status === 'admindone')} 
            blockPlus={(this.props.data.status === 'cancel' || this.props.data.status === 'admindone')} 
            key={index} 
            eleteFromCart={(id) => this.deleteFromCart(id)} 
            fromCart={true} 
            fromStock={this.props.data.type === 'stock'} 
            hideActions={true} 
            fromExchange={this.props.data.type === 'exchange'} 
            type={this.props.data.type} 
            data={item}
             />
        })
      }
      </div>
      {
        (this.state.positions.length === 0 && !this.state.loading) && <h3 className="p10">В заявке нет позиций</h3>
      }
      <div className="submitcartrequestbtnwrap">
                        {(this.state.positions.length > 0 && this.props.data.status === 'active' && this.context.usertype !== 'admin') && <Button type="primary" className="submitcartrequestbtn" onClick={() => this.submitRequest()}>Подтвердить заказ</Button>}
                    </div>
                    <RequestCommentsComponent id={this.props.data.id} />
        </Spin>
    )
  }
}

SingleRequestList.contextType = AppContext;

export default withRouter(SingleRequestList);
