import React from 'react';
import '../../App.scss';
import { Button, Card } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import AppContext from '../../components/common/appContext';
import { PageHeader} from 'antd';
import { withRouter} from 'react-router-dom';

class AboutComponent extends React.Component{
    constructor(props){
        super(props);
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "О приложении", key: "/stock/" }
        ];        
    }

    componentWillMount(){
        document.title = 'О приложении | Букетная.ОПТ';        
    }

    render(){        
        return [
                <Breadcrumb key={1} style={{ margin: '16px 0' }} list={this.breadcrumbs} />,
                <Card
                    key={2}
                    className="content-card"                    
                >
                    <PageHeader
                        className="site-page-header"
                        onBack={() => this.props.history.goBack()}
                        title="О приложении"                        
                    />
                    <div className="aboutapp">
                        <div className="logo_block">
                            <h1>Букетная.ОПТ</h1>
                            <p className="text">Версия: 1.0 от 1 декабря 2020</p>
                            <a href="mailto:info@mowins.ru">
                                <Button>Связь с разработчиками</Button>
                            </a>
                        </div>
                    </div>
                    
                </Card>
            ]
        
    }
}

AboutComponent.contextType = AppContext;

export default withRouter(AboutComponent);