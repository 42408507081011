import React from "react";
import "../../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../../components/common/appContext";
import {
  Spin,
  Card,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  Skeleton,
  Table,
  Tag,
  DatePicker,
  Space,
  message,
} from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs, Modal } from "antd";
import rest from "../../../helpers/rest";
import { PlusOutlined } from "@ant-design/icons";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../helpers/app";
//import 'moment/locale/zh-cn';
import locale from "antd/es/date-picker/locale/ru_RU";
import { FileTextOutlined } from "@ant-design/icons";
import TransactionFiles from "../../transactions/files/index";
import { Select } from "antd";
const { TextArea } = Input;
moment.locale("ru");

const { Option } = Select;
const { RangePicker } = DatePicker;

class TouchesHistory extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.formRefAdd = React.createRef();
    this.state = {
      loading: true,
      addModal: false,
      addLoading: false,
      tableData: [],
      touchresult: [],
      preordersLoad: false,
      ordersload: false,
      userPreorders: [],
      userOrders: [],
      touchtypesload: false,
      touchTypes: [],
    };

    this.getTouches = this.getTouches.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.historycolumns = [
      {
        title: "№",
        dataIndex: "CTID",
        sorter: (a, b) => a.CTID - b.CTID,
      },
      {
        title: "Дата",
        key: "createdAt",
        render: (text, record) => {
          return moment(record.createdAt).format("DD-MM-YYYY HH:mm");
        },
      },
      {
        title: "Имя",
        render: (text, record) => {
          return `${record.fname} ${record.sname}`;
        },
      },
      {
        title: "Результат контакта",
        render: (text, record) => {
          return `${record.TRNAME}`;
        },
      },
      {
        title: "Комментарий",
        render: (text, record) => {
          return `${record.COMMENT}`;
        },
      },
      {
        title: "Предзаказ",
        render: (text, record) => {
          if (record.PRID) {
            return (
              <Tag>
                №{record.PRID} на{" "}
                {moment(record.PRTODATE).format("DD MMM YYYY")}
              </Tag>
            );
          }
          //return `${record.COMMENT}`;
        },
      },
      {
        title: "Заказ",
        render: (text, record) => {
          if (record.REQID) {
            return <Tag>№{record.REQID}</Tag>;
          }
        },
      },
      // TPNAME
      {
        title: "Менеджер",
        render: (text, record) => {
          if (record.manager) {
            return (
              <Tag>
                {record.mfname} {record.msname}
              </Tag>
            );
          }
        },
      },
    ];
  }

  componentDidMount() {
    this.getTouches();
    rest.get("/api/Account/touchesresult").then((data) => {
      this.setState({ touchresult: data.data.data });
    });
    if (this.context.usertype == "admin") {
      this.historycolumns.push({
        title: "Тип взаимодействия",
        render: (text, record) => {
          if (record.TPNAME) {
            return <Tag>{record.TPNAME}</Tag>;
          }
        },
      });
    }
  }

  onSubmit() {
    console.log("123");
    //console.log( this.formRefAdd.current.getFieldsValue())
    let params = {
      ...this.formRefAdd.current.getFieldsValue(),
      ...{ userid: this.props.userid },
    };
    this.setState({ addLoading: true });
    rest.post("/Api/Account/touchespost", params).then((data) => {
      this.setState({ addLoading: false, addModal: false });
      this.getTouches();
    });
  }

  getTouches() {
    this.setState({ loading: true });
    rest
      .get("/Api/Account/touches", { userid: this.props.userid })
      .then((data) => {
        //console.log(data.data.data);
        this.setState({ loading: false, tableData: data.data.data });
      });
  }

  loadUserPreorders = () => {
    this.setState({ preordersLoad: true });
    let params = { userid: this.props.userid };
    rest.get("/api/Requests/getpreorders", params).then((data) => {
      this.setState({ userPreorders: data.data.data, preordersLoad: false });
    });
  };

  loadUserOrders = () => {
    this.setState({ ordersload: true });
    let params = { userid: this.props.userid, pagesize: "unset" };
    rest.get("/api/Requests/all", params).then((data) => {
      //console.log(data.data.data.list)
      this.setState({ userOrders: data.data.data.list, ordersload: false });
    });
  };

  loadTouchTypes = () => {
    this.setState({ touchtypesload: true });
    rest.get("/api/Requests/touchtypes").then((data) => {
      this.setState({ touchTypes: data.data.data, touchtypesload: false });
    });
  };

  render() {
    return [
      <Form
        onFinish={this.onSubmit}
        onFinishFailed={() => message.warn("Заполните данные")}
        name="basic"
        className="useredit"
        ref={this.formRef}
        layout="vertical"
        key={1}
      >
        <div className="userdateselector" style={{ marginBottom: "10px" }}>
          {/* <Form.Item name="date">
            <RangePicker locale={locale} />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "10px" }}
          >
            Фильтровать
          </Button> */}
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            shape="circle"
            onClick={() => this.setState({ addModal: true })}
            icon={<PlusOutlined />}
          />
        </div>
      </Form>,
      <Spin key={6} spinning={this.state.loading}>
        <Table
          dataSource={this.state.tableData}
          columns={this.historycolumns}
        />
        ,
      </Spin>,
      <Modal
        key={2}
        title="Контакт с клиентом"
        visible={this.state.addModal}
        onOk={() => this.formRefAdd.current.submit()}
        onCancel={() => this.setState({ addModal: false })}
      >
        <Spin spinning={this.state.addLoading}>
          <Form
            onFinish={this.onSubmit}
            onFinishFailed={() => message.warn("Заполните данные")}
            name="basic"
            className="useredit"
            ref={this.formRefAdd}
            layout="vertical"
          >
            <Form.Item
              label="Результат контакта"
              name="result"
              rules={[
                {
                  required: true,
                  message: "Укажите результат контакта",
                },
              ]}
            >
              <Select
                showSearch
                name="result"
                placeholder="Выберите результат контакта"
                optionFilterProp="children"
              >
                {this.state.touchresult.map((val, i) => {
                  return (
                    <Option key={val.id} value={val.id}>
                      {val.NAME}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Тип взаимодействия" name="touchtype">
              <Select
                name="touchtype"
                placeholder="Выберите тип"
                optionFilterProp="children"
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    this.loadTouchTypes();
                  }
                }}
                dropdownRender={(menu) => (
                  <div>
                    <Spin spinning={this.state.touchtypesload}>{menu}</Spin>
                  </div>
                )}
              >
                {this.state.touchTypes.map((val, i) => {
                  return (
                    <Option key={val.ID} value={val.ID}>
                      {val.NAME}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Созданный предзаказ" name="preorder">
              <Select
                name="preorder"
                placeholder="Выберите предзаказ"
                optionFilterProp="children"
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    this.loadUserPreorders();
                  }
                }}
                dropdownRender={(menu) => (
                  <div>
                    <Spin spinning={this.state.preordersLoad}>{menu}</Spin>
                  </div>
                )}
              >
                {this.state.userPreorders.map((val, i) => {
                  return (
                    <Option key={val.ID} value={val.ID}>
                      <Tag>
                        №{val.ID} на {moment(val.TODATE).format("DD MMM YYYY")}}
                      </Tag>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item label="Созданный заказ" name="request">
              <Select
                name="request"
                placeholder="Выберите заказ"
                optionFilterProp="children"
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    this.loadUserOrders();
                  }
                }}
                dropdownRender={(menu) => (
                  <div>
                    <Spin spinning={this.state.ordersload}>{menu}</Spin>
                  </div>
                )}
              >
                {this.state.userOrders.map((val, i) => {
                  return (
                    <Option key={val.id} value={val.id}>
                      <Tag>
                        №{val.id} от {moment(val.created).format("DD MMM YYYY")}
                      </Tag>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Пожалуйста выберите дату следующего звонка",
                },
              ]}
              label="Когда перезвонить ?"
              name="NEXT_CALL"
            >
              <DatePicker locale={locale} />
            </Form.Item>
            <Form.Item label="Комментарий" name="comment">
              <TextArea
                style={{
                  minHeight: "160px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                showCount
                maxLength={500}
                rows={4}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>,
    ];
  }
}

TouchesHistory.contextType = AppContext;

export default withRouter(TouchesHistory);
