import React from 'react';
import './App.scss';
import Loading from './components/loading';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  BrowserRouter
} from "react-router-dom"
import Login from './components/login/login';
import Root from './components/root/root';
import AppContext from './components/common/appContext';
import rest from './helpers/rest'
import jwt_decode from "jwt-decode";
import WelcomePageComponent from './components/welcome/index'
import { YMInitializer } from 'react-yandex-metrika';
import { Drawer } from 'antd';
/* eslint-disable react/no-direct-mutation-state */

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      auth: false,
      userinfo: {type: ''},
      localloading: false,
      loadinginfotext: '',
      drawerVisible: false,
      DrawerComponent: null,
      drawerTitle: ''
    }
    this.setLoad =  this.setLoad.bind(this);
    this.setUser = this.setUser.bind(this);
    
  }

  componentWillMount(){
    
    if(!localStorage.getItem('token')){
      this.setState({loading: false})
    }else{
      rest.get('/api/Account').then(user => {        
        this.setUser(jwt_decode(localStorage.getItem('token')))
      }).catch(err => {
        localStorage.removeItem('token');
        this.setState({loading: false})        
      })
    }
  }
  
  setLoad(value = false){
    this.setState({loading: value})
  }

  logout(loginas = false){    
    if(!localStorage.getItem('c_token')){
      localStorage.removeItem('token');
      this.setState({auth: false})
    }else{
      localStorage.setItem('token', localStorage.getItem('c_token'));
      localStorage.removeItem('c_token');
      this.props.history.push('/stock')
      window.location.reload('');
    }
    
  }

  getComponentFromState = (Component) => {    
    return Component
  }

  setUser(data){          
    this.setState({userinfo: data, auth: true, loading: false})    
    let path = window.location.pathname        
    this.props.history.push((path === '/' || path === '/login') ? '/stock' : path)
    this.setState({auth: true})    
  }
  render(){
    const { history } = this.props
    const pathname = window.location.pathname;    
    return (
      
      <AppContext.Provider value={{
        usertype: this.state.userinfo.type,
        id: this.state.userinfo.id,
        username: `${this.state.userinfo.fname} ${this.state.userinfo.sname}`,
        userdiscount : this.state.userinfo.discounts,
        setUser: (data) => {this.setUser(data)},
        logout: () => {this.logout()},
        setLoad: (value, str = '') => {this.setState({localloading: value, loadinginfotext: str})},
        openDrawer: (title = '', Component) => {this.setState({drawerVisible: true, drawerTitle: title, DrawerComponent: Component})},
        closeDrawer: () => this.setState({drawerVisible : false, DrawerComponent: null, drawerTitle: ''})
      }}>
      {
        this.state.localloading && <Loading str={this.state.loadinginfotext} />
      }
      <div className="App">
        {
          this.state.loading ? <Loading /> :
          <BrowserRouter>
            <Route path="*" exact render={() => {
              if(pathname === '/' || pathname === '/welcome'){
                return <Redirect to={this.state.auth ? pathname : '/welcome'} />
              }else{
                return <Redirect to={this.state.auth ? pathname : '/login'} />
              }
              
            }} />
            <Route history={history}  path='/login/' render={() => <Login />} />
            <Route history={history}  path='/welcome' render={() => <WelcomePageComponent />} />   
          {
            this.state.auth && <Switch>                      
            <Route history={history}  path='/' render={() => <Root />} />            
          </Switch>
          }          
          </BrowserRouter>        
        }
      </div>
      {
        
        process.env.NODE_ENV !== 'development' && this.state.userinfo.type != 'admin' && <YMInitializer accounts={[86925465]} options={{webvisor: true}} version="2"  />
        
      },
      <Drawer width={'auto'} style={{width: 'auto', maxWidth: '100%'}} key={443} title={this.state.drawerTitle} placement="right" onClose={() => this.setState({drawerVisible : false, DrawerComponent: null})} visible={this.state.drawerVisible}>
        {this.getComponentFromState(this.state.DrawerComponent)}
      </Drawer>
      </AppContext.Provider>
    );
  }
  
}
//qqq
export default withRouter(App)
