import React from 'react'
import '../../App.scss';
import {  Card } from 'antd';
import Breadcrumb from '../common/breadcrumb'
import AppContext from '../../components/common/appContext';
import { PageHeader } from 'antd';
import { withRouter } from 'react-router-dom';

class Account extends React.Component{
    constructor(props){
        super(props)
        this.breadcrumbs = [
            { title: "Букетная.ОПТ", key: "/" },
            { title: "Личный кабинет", key: "/account/" }
        ];
    }
    componentWillMount(){
        document.title = 'Личный кабинет | Букетная.ОПТ';        
    }
    render(){
        return[
            <Breadcrumb key={1} style={{ margin: '16px 0' }} list={this.breadcrumbs} />,
            <Card
                key={2}
                className="content-card"                    
            >
                <PageHeader
                    className="site-page-header"
                    onBack={() => this.props.history.goBack()}
                    title="Личный кабинет"
                                         
                />
            </Card>
        ]
        
    }
}

Account.contextType = AppContext;

export default withRouter(Account);