import React from "react";
import "../../App.scss";
import { message } from "antd";
import { Form, Input, Button } from "antd";
import Loading from "../loading";
import AppContext from "../../components/common/appContext";
import rest from "../../helpers/rest";
import {  
  withRouter,  
} from "react-router-dom";
import jwt_decode from "jwt-decode";
const layout = {
  labelCol: { sm: 24 },
  wrapperCol: { sm: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formload: false,
    };
    this.formRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmitError() {
    message.error("Ошибка");
  }

  onSubmit() {
    
    this.setState({ formload: true });
    let data = this.formRef.current.getFieldValue();

    rest
      .post("/api/Account/token", data)
      .then((data) => {
        localStorage.setItem("token", data.data.token);
        this.context.setUser(jwt_decode(data.data.token));
      })
      .catch((err) => {
        let status = err.response ? err.response.status : 200;
        if (status === 401) message.error("Неверный логин или пароль");
        if (status === 403)
          message.error(
            "Ваш аккаунт заблокирован. Обратитесь к администратору"
          );
        this.setState({ formload: false });
      });
  }

  render() {
    return (
      <div id="login">
        <div className="logo_block gradientanimate">
          <h1>Букетная.ОПТ</h1>
        </div>
        <div className="auth_form">
          <h2>Авторизация</h2>
          <p className="subtitle">
            Данные для входа можно получить у менеджера
          </p>
          <Form
            {...layout}
            onFinish={this.onSubmit}
            onFinishFailed={this.onSubmitError}
            name="basic"
            ref={this.formRef}
            layout="vertical"
          >
            {!this.state.formload ? (
              <div className="formitem_list">
                <Form.Item
                  label="Логин"
                  name="login"
                  rules={[
                    { required: true, message: "Пожалуйста введите логин" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Пароль"
                  name="password"
                  rules={[
                    { required: true, message: "Пожалуйста Введите пароль!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" className="loginbtn" htmlType="submit">
                    Войти
                  </Button>
                </Form.Item>
              </div>
            ) : (
              <Loading mini={true} />
            )}
          </Form>
        </div>
      </div>
    );
  }
}

Login.contextType = AppContext;

export default withRouter(Login);
