import * as React from "react";
import {
  Spin,
  Card,
  Row,
  Col,
  PageHeader,
  Button,
  Upload,
  message,
} from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../helpers/rest";
import Breadcrumb from "../common/breadcrumb";
import { UploadOutlined } from "@ant-design/icons";
import CategorySelectComponent from "../common/categoryselect";
import ColorSelectComponent from "../common/colorselect";
import Cropper from "react-easy-crop";
import { Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";

const layout = {
  labelCol: { offset: 1, sm: 24, md: 12, xs: 12 },
  wrapperCol: { offset: 1, sm: 24, md: 12, xs: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24, md: 24, xs: 24 },
};

class CatalogItemEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crooperModalVisible: false,
      loading: true,
      showcrop: false,
      catalogdata: "",
      imageloadin: false,
      blobImage: null,
      imageurl: "",
      image: "https://i.ytimg.com/vi/NKk6rGDpq6Y/sddefault.jpg",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1 / 3,
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 16 / 16,
      },
    };
    this.formRef = React.createRef();
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Каталог", key: "/catalog/" },
      { title: "Редактирование" },
    ];
    this.uploaddata = {
      name: "file",
      action: rest.uploadavatarurl(this.props.match.params.id),
      onChange: (info) => {
        this.onChangeImage(info);
      },
    };
    this.onSubmit = this.onSubmit.bind(this);
    //this.onChangeImage = this.onChangeImage.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.setCrop = this.setCrop.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.setCropImage = this.setCropImage.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  blobToUint8Array(b) {
    var uri = URL.createObjectURL(b),
      xhr = new XMLHttpRequest(),
      i,
      ui8;

    xhr.open("GET", uri, false);
    xhr.send();

    URL.revokeObjectURL(uri);

    ui8 = new Uint8Array(xhr.response.length);

    for (i = 0; i < xhr.response.length; ++i) {
      ui8[i] = xhr.response.charCodeAt(i);
    }

    return ui8;
  }

  uploadFile() {    
    let url = rest.uploadavatarurl(this.props.match.params.id);
    let xhr = new XMLHttpRequest();
    let formData = new FormData();
    xhr.open("POST", url, true);
    formData.append("file", this.state.blobImage, "image.jpg");
    
    xhr.send(formData);
    this.setState({ imageloadin: true, crooperModalVisible: false });
    xhr.onload = (e) => {
      //imageurl
      this.setState({
        imageloadin: false,
        imageurl: JSON.parse(e.currentTarget.response).image,
      });
    };
  }

  // onCropChange = (crop) => {
  //   this.setState({ crop });
  // };

  // onCropComplete = (croppedArea, croppedAreaPixels) => {
  //   console.log(croppedArea, croppedAreaPixels);
  // };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onChangeImage(info) {
    if (info.file.status !== "done") {
      this.setState({ imageloadin: true });
    } else {
      this.setState({ imageloadin: false, imageurl: info.file.response.image });
    }
  }

  onSubmit() {
    this.setState({ loading: true });
    let data = this.formRef.current.getFieldsValue();
    data.id = this.props.match.params.id;
    rest.post("/api/Catalog/", data).then((data) => {
      this.setState({ loading: false });
      this.props.history.push("/catalog/");
    });
  }

  componentWillMount() {
    rest
      .get("/api/Catalog/", { id: this.props.match.params.id })
      .then((data) => {
        let positondata;
        positondata = data.data.data.list;
        if (positondata === 0) {
          this.props.history.push("/");
        } else {
          positondata = positondata[0];
        }
        this.formRef.current.setFieldsValue({
          name: positondata.name,
          category: positondata.category,
          color: positondata.color,
        });
        this.setState({
          image: rest.getavatarurl(
            this.props.match.params.id,
            positondata.image
          ),
        });
        this.setState({
          loading: false,
          catalogdata: positondata,
          imageurl: positondata.image,
        });
      });
  }

  setCrop(e) {
    //   //console.log(e.target.files)
    //   let path = (window.URL || window.webkitURL).createObjectURL(e.target.files[0]);
    //   console.log(path)
    //   this.setState({image: path})
    this.setState({ crop: e });
  }

  setCropImage(e) {
    let path = (window.URL || window.webkitURL).createObjectURL(
      e.target.files[0]
    );
    
    this.setState({ image: path }, () => {
      this.setState({ showcrop: true, crooperModalVisible: true });
    });
  }

  setZoom(e) {
    this.setState({ zoom: e });
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        
        resolve(this.fileUrl);
        this.setState({ blobImage: blob });
      }, "image/jpeg");
    });
  }

  onSelectFile = (e) => {
    //console.log(e.target.files[0].type);
    if (e.target.files && e.target.files.length > 0) {
      let type = e.target.files[0].type;
      if(type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/png'){
        message.warning('Файл не соответствует типу jpeg,png')
      }else{
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          this.setState({ src: reader.result });
          this.setState({ showcrop: true, crooperModalVisible: true });
        });
        reader.readAsDataURL(e.target.files[0]);
      }
      
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    
    this.setState({ crop });
    //console.log(this.onCropComplete())
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });      
    }
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <Row key={2}>
        <Col span={24} md={24} xs={24}>
          <Card key={2} className="content-card">
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Редактирование позиции"
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.formRef.current.submit()}
                >
                  Сохранить
                </Button>,
              ]}
            />
            <div className="catalogedit">
              <Spin spinning={this.state.loading}>
                <Row>
                  <Col md={6} lg={6} xs={24} sm={24}>
                    <input type="file" accept="image/jpeg,image/png" id="selectfile" className="hidden" onChange={this.onSelectFile} />
                    
                    <Spin spinning={this.state.imageloadin}>
                      {/* <div
                        className="catalogedit__imagecover"
                        style={{
                          background: `url(${rest.getavatarurl(
                            this.props.match.params.id,
                            this.state.imageurl
                          )})`,
                        }}
                      ></div> */}
                      <img
                        class="editcover"
                        src={rest.getavatarurl(
                          this.props.match.params.id,
                          this.state.imageurl
                        )}
                      />
                      <Button onClick={() => document.getElementById('selectfile').click()} className="selectimgbtn" icon={<UploadOutlined />}>Загрузите изображение</Button>

                      {/* <Upload showUploadList={false}  {...this.uploaddata} className="uploadcustom">
                                <Button icon={<UploadOutlined />}>Загрузите изображение</Button>
                            </Upload> */}
                    </Spin>
                  </Col>
                  <Col md={18} lg={18} xs={24} sm={24}>
                    <Form
                      {...layout}
                      onFinish={this.onSubmit}
                      onFinishFailed={() => message.warn("Заполните данные")}
                      name="basic"
                      className="useredit"
                      ref={this.formRef}
                      layout="vertical"
                    >
                      <Form.Item
                        label="Название"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Пожалуйста введите название",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <CategorySelectComponent mode="none" required={true} />

                      <ColorSelectComponent
                        all={true}
                        mode="none"
                        required={true}
                      />

                      <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                          Сохранить
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Spin>
            </div>
          </Card>
        </Col>
      </Row>,
      <Modal
        key={3}
        title="Новое изображение"
        visible={this.state.crooperModalVisible}
        onOk={() => this.uploadFile()}
        onCancel={() => this.setState({ crooperModalVisible: false })}
        width={1000}
        maskClosable={false}
      >
        <div className="cropwrap">
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
          {croppedImageUrl && (
            <img
              alt="Crop"
              style={{ maxWidth: "100%" }}
              src={croppedImageUrl}
            />
          )}
        </div>
      </Modal>,
    ];
  }
}

export default withRouter(CatalogItemEdit);
