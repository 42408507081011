import React from 'react';
import '../../App.scss';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AppContext from '../../components/common/appContext';
import { Link } from 'react-router-dom';


class TopBar extends React.Component{
    constructor(props){
        super(props);
        this.logout = this.logout.bind(this);
        this.state = {
            loginAs: false
        }
        //console.log(this.props.usertype)
        
    }

    logout(){
        this.context.logout(this.state.loginAs);
    }

    componentWillMount(){
        if(localStorage.getItem('c_token')){
            this.setState({loginAs: true})
        }
    }

    

    render(){
        let test = false;
        if((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || window.location.host != 'opt.buketnaya.store'){
            test = true;
        }
        const menu = (
            <Menu>
                {/* <Menu.Item>
                <Link to='/account/'>
                        Личный кабинет 
                </Link>        
              </Menu.Item>   */}
              
              <Menu.Item>
                <Link to='/resetpass/'>
                        Изменить пароль
                </Link>        
              </Menu.Item>
              {
                  this.props.usertype == 'user' && <Menu.Item>
                  <Link to='/profile/'>
                          Аккаунт
                  </Link>        
                </Menu.Item>  
              }  
              <Menu.Item>
                <Link to='/aboutapp/'>
                        О приложении
                </Link>        
              </Menu.Item>  
              <Menu.Item onClick={() => this.logout()}>        
                  Выйти        
              </Menu.Item>     
            </Menu>
          );
        return(
            
            <div className="topbar">
                {
                    test && <h1 style={{fontSize: '60px', position: 'relative', left: '-50px', top: '10px'}}>{`ТЕСТОВАЯ ВЕРСИЯ`}</h1>
                }
                <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link">
                    {this.state.loginAs ? 'Вы вошли как: ' : ''}{this.context.username} <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        )
    }
}

TopBar.contextType = AppContext;

export default TopBar;