import { Modal, Button } from "antd";
import React from "react";


class AppHelper extends React.Component {
  static setPageSize(size) {
    localStorage.setItem("pageSize", size);
  }


  static getDrawer(Component){
    return <Component />
  }

  static getRequestType(type){
    switch(type){
      case 'stock' : {
        return 'Склад';
        break;
      }
      case 'exchange' : {
        return 'Биржа';
        break;
      }
    }
  }

  

  static secToHHMMSS(secs){
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
  }

  static formatUsPhone(phone) {

    var phoneTest = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

    phone = phone.trim();
    var results = phoneTest.exec(phone);
    if (results !== null && results.length > 8) {

        return "(" + results[3] + ") " + results[4] + "-" + results[5] + (typeof results[8] !== "undefined" ? " x" + results[8] : "");

    }
    else {
         return phone;
    }
}

  // Информационное модальное окно
  static autocloseModal(text, time = 0) {
    let secondsToGo = time;
    let content = `Это окно закроется через ${secondsToGo} сек.`;
    if(time == 0){content = ''};
    const modal = Modal.success({
      title: text,
      content: content
    });
    if(time > 0){
      const timer = setInterval(() => {
        secondsToGo -= 1;
        if(secondsToGo > 0){
          modal.update({
            content: `Это окно закроется через ${secondsToGo} сек.`,
          });
        }        
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
    
  }

  static num_word(value, words) {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];
    return words[2];
  }

  static discountArray() {
    let percentrate = [];
    Array.from(Array(16).keys()).filter(i => i != 0).map(i => {
      percentrate.push({
        name: `Скидка ${i}%`,
        value: `percent_${i}`
      })
    })
    return [
      { name: "Скидка до себестоимости", value: "selfcost" },
      { name: "Нет", value: null },
      ...percentrate
    ];
  }

  static PaymentType(name) {
    switch (name) {
      case "cash": {
        return "Наличные";
        break;
      }
      case "cashless": {
        return "Безнал";
        break;
      }
      case "cashtransfer": {
        return "Перевод";
        break;
      }
    }
  }

  static toCurrency = (n, curr, LanguageFormat = undefined) => {        
    return Intl.NumberFormat(LanguageFormat, {
      style: "currency",
      currency: curr,
    }).format(n);
  }    
    

  static get statusses(){
    let stats = [
      { name: "done", label: "Подтверждена пользователем" },
      { name: "admindone", label: "Подтверждена администратором" },
      { name: "collected", label: "Заказ собран" },
      { name: "issued", label: "Заказ выдан" },
      { name: "cancel", label: "Отменена" },
      { name: "active", label: "Активная" },
    ];
    return stats;
  }

  static statusList(status = null, widthaccess = false) {
    let stats = [
      { name: "done", label: "Подтверждена пользователем" },
      { name: "admindone", label: "Подтверждена администратором" },
      { name: "collected", label: "Заказ собран" },
      { name: "issued", label: "Заказ выдан" },
      { name: "cancel", label: "Отменена" },
      { name: "active", label: "Активная" },
    ];

    let access_statusses = [
      { name: "active", access: ["done", "cancel"], label: "Активная" },
      {
        name: "done",
        access: ["active", "cancel", "admindone", "issued", "collected"],
        label: "Подтверждена пользователем",
      },
      {
        name: "admindone",
        access: ["active", "issued", "done", "cancel", "collected"],
        label: "Подтверждена администоратором",
      },
      {
        name: "collected",
        access: ["cancel", "active", "admindone", "issued"],
        label: "Заказ собран",
      },
      {
        name: "issued",
        access: ["active", "cancel", "admindone", "done", "collected"],
        label: "Заказ выдан",
      },
      { name: "cancel", access: [], label: "Отменена" },
    ];

    let filtered = access_statusses;
    if (status && widthaccess) {
      filtered = access_statusses
        .filter((elem) => elem.name != status)
        .filter((i) =>
          access_statusses
            .filter((as) => as.name == status)[0]
            .access.includes(i.name)
        );
    }

    //filtered = access_statusses.filter(i => access_statusses.filter((elem) => elem.name == status)[0].access.includes(i.name));
    return filtered;

    // filtered = menues.filter((elem) => elem.code !== status).filter(i => access_statusses.filter(as => as.status == status)[0].access.includes(i.code));
    // if (status === "cancel") {
    //   filtered = menues.filter((elem) => elem.code === "cancel");
    // }

    // if (status && status == "cancel") {
    //   stats = stats.filter((item) => item.name == "cancel");
    // }
  }
  static getRequestStatus(status) {
    let result = { color: "red", label: "Неизвесный статус" };
    switch (status) {
      case "done": {
        result = { color: "orange", label: "Подтверждена пользователем" };
        break;
      }
      case "issued": {
        result = { color: "grey", label: "Заказ выдан" };
        break;
      }
      case "collected": {
        result = { color: "blue", label: "Заказ собран" };
        break;
      }
      case "admindone": {
        result = { color: "green", label: "Подтверждена администратором" };
        break;
      }
      case "cancel": {
        result = { color: "red", label: "Отменена" };
        break;
      }
      case "active": {
        result = { color: "green", label: "Активная" };
        break;
      }
      default: {
        result = { color: "red", label: "Неизвестный статус" };
      }
    }
    return result;
  }
}

export default AppHelper;
