import React from "react";
import "../../../App.scss";
import {
  Spin,
  Card,
  Row,
  Col,
  Switch,
  message,
  Select,
  Popconfirm,
  InputNumber,
  DatePicker
} from "antd";
import Breadcrumb from "../../common/breadcrumb";
import { PageHeader, Button, Form, Input } from "antd";
import { withRouter } from "react-router-dom";
import rest from "../../../helpers/rest";
import MaskedInput from "antd-mask-input";
import AppHelper from "../../../helpers/app";
import UserSelectModalComponent from "../../users/userselectmodal";
import CatalogSelect from "../../excelparser/catalogselect/catalogselect";
import SingleGoodComponent from "../../catalog/singlegood";
import * as moment from "moment";
import AppContext from "../../common/appContext";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
moment.locale("ru");

const { TextArea } = Input;


const { Option } = Select;

const layout = {
  labelCol: { offset: 6, sm: 12 },
  wrapperCol: { offset: 6, sm: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

class PreorderEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cataspan: 24,
      loading: true,
      userSelectVisible: false,
      userid: null,
      userinfoloading: false,
      foruser: true,
      positions: [],

      breadcrumbs: [
        { title: "Букетная.ОПТ", key: "/" },
        { title: "Предзаказы", key: "/preorder" },
        { title: "Редактирование" },
      ],
    };
    this.preorderForm = React.createRef();
    this.preorderFormArea = React.createRef();
  }

  componentWillMount() {
    this.getPreorder();
    if(this.context.usertype == 'user'){
      this.selectUserFromModal(this.context.id);
    }else{
      this.setState({foruser: false})
    }
  }

  getPreorder = () => {
    this.setState({ loading: true });
    rest
      .get("/api/Requests/getpreorder", { id: this.props.match.params.id })
      .then((data) => {
        
        if (data.data.data.USER_ID && this.context.usertype != 'user') {
          this.selectUserFromModal(data.data.data.USER_ID);
        }
        if(data.data.data.TODATE){
          this.preorderForm.current.setFieldsValue({
            date: moment(data.data.data.TODATE)
          });
        }
        if (data.data.data.comment && this.context.usertype != 'user') {
          this.preorderFormArea.current.setFieldsValue({
            allcomment: data.data.data.comment
          });
        }
        if (data.data.data.positions) {
          data.data.data.positions.map((i) => {
            this.addPosition(i);
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        
        this.props.history.push('/preorder')
      });
  };

  savePreorder = async () => {
    let errors = this.state.positions.map(
      async (item) => await item.ref.current.validateFields()      
    );
    let dateValidate = await this.preorderForm.current.validateFields();
    if(!dateValidate){
      message.error("Укажите дату");
      return;  
    }

    let preorderСomment = "";
    
    if(this.preorderFormArea.current !== null){
      preorderСomment = await this.preorderFormArea.current.validateFields();
    }

    if (errors.length == 0) {
      message.error("Ошибка предзаказа");
      return;
    }
    Promise.all(errors)
      .then((e) => {
        // Продолжаем работу
        // Проверка наличия выбранного клиента
        if (!this.state.userid) {
          message.error("Выберите пользователя");
          return;
        } else {
          // Компануем данные
          let positions = [];
          this.state.positions.map((item) => {
            positions.push({ ...item.ref.current.getFieldsValue() });
          });


          let data = {
            userid: this.state.userid,
            preorderid: this.props.match.params.id,
            positions: positions,
            toDate: this.preorderForm.current.getFieldsValue().date,
            comment: preorderСomment.allcomment
          };
          console.log(data);
          this.setState({ loading: true });
          rest.update("/api/Requests/preorderupdate", data).then((data) => {
            this.setState({ loading: false });
            this.props.history.push("/preorder");
            
          });
        }
      })
      .catch(() => {
        message.error("Заполните все поля!");
      });
  };

  selectUserFromModal = (id) => {
    this.setState({
      userSelectVisible: false,
      userid: id,
      userinfoloading: true,
    });
    rest.get("/api/Account", { userid: id }).then((data) => {
      if (data.data.data.length == 0) {
        message.error("Ошибка, пользователь не найден");
        this.setState({ userid: false, userinfoloading: false });
      } else {
        this.setState({ userinfo: data.data.data[0], userinfoloading: false });
      }
    });
  };

  deletePos = (id) => {
    let pos = this.state.positions;
    pos = pos.filter(i =>  i.id != id);
    this.setState({positions: pos});
  }

  addPosition = (elem) => {
    let pos = this.state.positions;
    pos.push({ ...elem, ref: React.createRef()});
    this.setState({ positions: pos });
    console.log(this.state.positions);
  };

  render() {
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.state.breadcrumbs}
      />,
      <Spin key={2} spinning={this.state.loading}>
        <Row key={2}>
          <Col key={100} span={this.state.cataspan}>
            <Card className="content-card">
              <PageHeader
                className="site-page-header"
                onBack={() => this.props.history.goBack()}
                title="Редактирование предзаказа"
                key={234}
              />
              <div key={366} className="preorder__conteot">
                <Row key={112} className="preorder___inner">
                  <Col md={6} sm={24}>
                    <div className="left__inner">
                      <div className="user__inner" key={387}>
                        <h3 key={223}>Клиент</h3>
                        <div className="user__inner_content">
                          <UserSelectModalComponent
                            onSelect={(id) => this.selectUserFromModal(id)}
                            key={3}
                            onClose={() =>
                              this.setState({ userSelectVisible: false })
                            }
                            visible={this.state.userSelectVisible}
                          />
                          <Spin spinning={this.state.userinfoloading}>
                            {this.state.userid && (
                              <div className="userinfo__data mtn">
                                {this.state.userinfo && (
                                  <div className="user__params">
                                    <span className="fio">{`${this.state.userinfo.fname} ${this.state.userinfo.sname}`}</span>
                                    <span className="login">{`@${this.state.userinfo.login}`}</span>
                                    <span className="phone">{`${this.state.userinfo.phone}`}</span>
                                    <span className="regdate">
                                      <span className="label">
                                        Дата регистрации:
                                      </span>
                                      {`${moment(
                                        this.state.userinfo.regdate
                                      ).format("DD MMMM YYYY")}`}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </Spin>
                          {!this.state.foruser && <Button
                            onClick={() =>
                              this.setState({ userSelectVisible: true })
                            }
                            className="user__select_btn"
                            type="primary"
                          >
                            Выбрать пользователя
                          </Button>}
                        </div>
                      </div>
                      <div className="preorderinfo__inner">
                      <h3>Предзаказ</h3>
                        <div className="preorder__form">                          
                          <Form key={994} ref={this.preorderForm} layout="vertical">
                            <Form.Item key={1} label="Дата" name="date"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Пожалуйста введите дату",
                                },
                              ]}
                            >
                              <DatePicker locale={locale}  />
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      {!this.state.foruser ?
                        <div className="preorderinfo__inner">
                        <h3>Комментарий к предзаказу</h3>
                          <div className="preorder__form">                          
                            <Form key={994} ref={this.preorderFormArea} layout="vertical">
                            <Form.Item                                    
                                key={11}
                                name="allcomment"
                                >
                                  <TextArea />
                                </Form.Item>
                            </Form>
                          </div>
                        </div> : ''
                      }
                    </div>
                  </Col>
                  <Col key={101} md={{span: 17, offset: 1}} sm={{span: 24, offset: 0}}>
                    <div key={33887} className="preorder__position_wrapper">
                      <h3 key={986}>Позиции</h3>
                      <div key={330} className="positions__list stocklist mini">
                        <div className="position__item add__new" key={3376}>
                          <CatalogSelect
                            key={338}
                            canSelect={true}
                            label="Добавить новый"
                            icon={true}
                            selected={this.state.catalogid ? true : false}
                            onSelect={(catalog) => this.addPosition(catalog)}
                          />
                        </div>
                        {this.state.positions.map((elem, i) => {
                          return (
                            <div className="position__item" key={elem.id + 123}>
                              <SingleGoodComponent
                                data={elem}
                                hideActions={true}
                                forSelect={false}
                                forPreorder={true}
                                key={223}
                              />
                              <div key={376} className="position__item_form">
                                <Form
                                  key={994}
                                  ref={elem.ref}
                                  layout="vertical"
                                >
                                  <Form.Item
                                    key={1}
                                    label="Количество"
                                    name="quality"
                                    initialValue={elem.quality || null}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Пожалуйста введите количество",
                                      },
                                    ]}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    key={8}
                                    label="Рост"
                                    name="growth"
                                    initialValue={elem.growth || null}                                    
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item
                                    key={2}
                                    label="Замена"
                                    name="change"
                                    initialValue={elem.TOCHANGE || ''}                                    
                                  >
                                    <TextArea />
                                  </Form.Item>
                                  <Form.Item
                                    key={3}
                                    label="Максимальная цена"
                                    name="maxprice"
                                    initialValue={elem.MAXPRICE || ''}                                    
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                  <Form.Item                                    
                                    key={4}
                                    label="Комментарий администратора"
                                    name="admincomment"
                                    initialValue={elem.ACOMMENT || ''}                                    
                                    disabled={this.state.foruser}
                                  >
                                    <TextArea disabled={this.context.usertype != 'admin'} />
                                  </Form.Item>
                                  <Form.Item                                    
                                    key={11}
                                    label="Комментарий пользователя"
                                    name="ucomment"
                                    initialValue={elem.UCOMMENT || ''}                                    
                                    disabled={this.state.foruser}
                                  >
                                    <TextArea disabled={this.context.usertype != 'admin'} />
                                  </Form.Item>
                                  
                                  <Form.Item
                                    key={5}
                                    initialValue={elem.id}
                                    hidden={true}
                                    name="id"
                                  >
                                    <Input />
                                  </Form.Item>

                                  <div className="pos__actions">
                                    <Button onClick={() => this.deletePos(elem.id)}>Удалить</Button>
                                  </div>

                                </Form>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="preorder__actions">
                        {this.state.positions.length > 0 && (
                          <Button
                            onClick={() => this.savePreorder()}
                            type="primary"
                          >
                            Сохранить
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        ,
      </Spin>,
    ];
  }
}
PreorderEditComponent.contextType = AppContext;
export default withRouter(PreorderEditComponent);
