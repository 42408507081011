import React from "react";
import "../../App.scss";
import { NavLink } from "react-router-dom";
import { message, Skeleton } from "antd";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Modal,
  Button,
  Form,
  Input,
  Spin,
  DatePicker,
  Pagination
} from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm, Table, Tag, Radio } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import rest from "../../helpers/rest";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import locale from "antd/es/date-picker/locale/ru_RU";
import AppHelper from "../../helpers/app";
import * as moment from "moment";
import "moment/locale/ru";
import PreordersView from "../requests/preorder/forview/index";
import TotalPrice from "../requests/totalprice";
import SingleRequestList from "../requests/singlerequestlist";
moment.locale("ru");

const { RangePicker } = DatePicker;


class Calls extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "История звонков", key: "/calls/" },
    ];
    this.state = {
      loading: true,
      filterdate: [],
      callsTableData:[],
      pageSize: 10,
      totalSize: 0,
      currentPage: 1,
      filterParams:[]
    };

    this.callscolumns = [
      {
        title: "Администратор",
        dataIndex: "fiomanager",
        render: (text, record) => {
          return `${record.manager}`;
        },
      },
      {
        title: "Клиент",
        dataIndex: "fioclients",
        render: (text, record) => {
          return `${record.clients}`;
        },
      },
      {
        title: "Комментарий",
        dataIndex: "comment",
        className:"commentTable",
        render: (text, record) => {
          if(record.COMMENT != ""){
            return `${record.COMMENT}`;
          }
          else{
            return <Tag color="red">Не указан</Tag>;
          }
          
        },
      },
      {
        title: "Результат",
        dataIndex: "result",
        render: (text, record) => {
          if(record.result != ""){
            return `${record.result}`;
          }
          else{
            return <Tag color="red">Не указан</Tag>;
          }
        },
      },
      {
        title: "Дата звонка",
        dataIndex: "data",
        render: (text, record) => {
          return  moment(record.createdAt).format("DD-MM-YYYY").replace(/\-/g, '.');
        },
      },
      {
        title: "Заказ",
        dataIndex: "request",
        render: (text, record) => {
          if(record.REQUEST != null){
            return <span
            style={{ color: "#1890ff", cursor:"pointer"}}
            onClick={() => {
              this.showRequestPositions(record.REQUEST, record.type);
            }}
          >{record.REQUEST}</span>;
          }
          else{
            return <Tag color="red">Нет</Tag>;
          }
          
        },
      },
      {
        title: "Предзаказ",
        dataIndex: "preorder",
        render: (text, record) => {
          if(record.PREORDER != null){
            return <span
            style={{ color: "#1890ff", cursor:"pointer"}}
            onClick={() => {
              this.viewPreorder(record.PREORDER);
            }}
          >{record.PREORDER}</span>;
          }
          else{
            return <Tag color="red">Нет</Tag>;
          }
         
        },
      },
      {
        title: "Способ связи",
        dataIndex: "touchtype",
        render: (text, record) => {
          if(record.touchtype != null){
            return `${record.touchtype}`;
          }
          else{
            return <Tag color="red">Не указан</Tag>;
          }
         
        },
      },
      {
        title: "Следующий звонок",
        dataIndex: "nextcall",
        render: (text, record) => {
          if(record.NEXT_CALL != null){
            return moment(record.NEXT_CALL).format("DD-MM-YYYY").replace(/\-/g, '.');
          }
          else{
            return <Tag color="red">Не назначен</Tag>;
          }
        },
      },
    ];

    document.title = "История звонков | Букетная.ОПТ";
    this.formRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.getHistoryCalls = this.getHistoryCalls.bind(this);
    this.onChange = this.onChange.bind(this);
  
  }

  showRequestPositions = (id, type) => {
    this.context.openDrawer(
      `Просмотр заказа №${id}`,
      <SingleRequestList
        data={{ id: id, type: type }}
        update={() => {
          this.setState({ totalvisible: false });
          setTimeout(() => {
            this.setState({ totalvisible: true });
          }, 3);
        }}
      />
    );
  };

  viewPreorder = (id) => {
    console.log(id);
    this.context.openDrawer(
      `Просмотр предзаказа №${id}`,
      <PreordersView id={id} />
    );
  };
  
  onChange(page,size) {
    this.setState({currentPage: page, pageSize: size}, function(){
      this.getHistoryCalls(this.state.filterdate[0],
        this.state.filterdate[1]);     
    })
    
  }
  componentDidMount() {

    this.formRef.current.setFieldsValue({
      date: [moment(new Date()).subtract("30", "day"), moment(new Date())],
    });
    this.setState(
      {
        filterdate: [
          moment(new Date()).subtract("30", "days").format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD"),
        ],
      },
      () => {
        this.getHistoryCalls(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
      }
    );
  }

  

  onSubmit() {
    let formdata = this.formRef.current.getFieldsValue();
    this.setState(
      {
        filterdate: [
          moment(formdata.date[0]).format("YYYY-MM-DD"),
          moment(formdata.date[1]).format("YYYY-MM-DD"),
        ],
      },
      () => {
        this.getHistoryCalls(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
      }
    );

  }

  getHistoryCalls(start = null, end = null) {
    this.setState({loading: true });
    let params = this.state.filterParams;
    params.page = this.state.currentPage;
    params.pagesize = this.state.pageSize;
    params.start = start;
    params.end = end;
    rest
      .get("/api/Account/getcustomercallhistory",params)
      .then((data) => {
        this.setState({
          callsTableData: data.data.data.list,
          totalSize:data.data.data.count,
          loading: false,
        });
      });
  }

  render() {
    return [
        <Breadcrumb
            key={1}
            style={{ margin: "16px 0" }}
            list={this.breadcrumbs}
        />,
        <Card key={2} className="content-card">
            <PageHeader
                className="site-page-header"
                onBack={() => this.props.history.goBack()}
                title="История звонков"
            />
            <Spin spinning={this.state.loading}>
            <div className="p20">
              <Form
                onFinish={this.onSubmit}
                name="basic"
                ref={this.formRef}
                className="useredit"
                layout="vertical"
              >
                <div className="userdateselector">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста выберите диапазон",
                      },
                    ]}
                    name="date"
                  >
                    <RangePicker locale={locale} defaultPickerValue={moment(new Date()).subtract("30", "day"), moment(new Date())}/>
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                  >
                    Показать
                  </Button>
                </div>
              </Form>
              </div>
              <div className="managerinfo callHistory" style={{ marginTop: "30px" }}>
    
                   <Table
                    dataSource={this.state.callsTableData}
                    columns={this.callscolumns}
                    pagination={false}
                  />
                  <div className="m10">
                <Pagination
                      defaultCurrent={1}
                      current={this.state.currentPage}
                      pageSize={this.state.pageSize} 
                      onChange={this.onChange}
                      total={this.state.totalSize}
                      showSizeChanger={false}
                    />
              </div>
        
              </div>
              
          </Spin>
          
        </Card>,
    ]
  }
}

Calls.contextType = AppContext;

export default withRouter(Calls);
