import * as React from 'react';
import { Breadcrumb, Skeleton } from 'antd';
import { NavLink } from 'react-router-dom';
import AppHelper from '../../../helpers/app'
import rest from '../../../helpers/rest';
import RequestsV2Filter from './rfilter';
import RequestListV2 from './request';

class RequestsV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.reqref = React.createRef();
    }

    filter = (params) => {
        let temp = {};
        Object.keys(params).map(i => {
            if(params[i]){
                temp[i] =  params[i];
            }
        })
        this.reqref.current.getRequests(temp, true);
    }
    

    render() {        
        return [
            <div className="requests__v2_wrapper" key={1}>
                <RequestListV2 ref={this.reqref} />
                <RequestsV2Filter onFilter={(params) => this.filter(params)} />
            </div>
            
        ]
    }
}

export default RequestsV2;
