import React from "react";
import "../../App.scss";
import { Spin, Card, Row, Col, Pagination } from "antd";
import Breadcrumb from "../common/breadcrumb";
import {
  PageHeader,
  Button,
  Collapse,
  Select,
  Table,
  Tag,
  Divider,
  Popconfirm,
} from "antd";
import { withRouter, NavLink } from "react-router-dom";
import rest from "../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import * as moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')


class writeOffs extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Списания", key: "/writeOffs/" },
    ];
    this.state = {
      cataspan: 24,
      loading: true,
      writeOffsList: [],
      pageSize: 15,
      totalSize: 0,
      currentPage: 1,
      filterParams: {}
    };
    this.getWriteOffs = this.getWriteOffs.bind(this);
    this.onChange = this.onChange.bind(this);
    this.columns = [
      {
        title: "Дата",
        dataIndex: "date",
        render: (text, record) => {
          let date = moment(record.date).format("DD-MM-YYYY").replace(/\-/g, '.')
          return `${date}`;
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) => new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD')
        
      },
      { title: "Название", dataIndex: "name" },
      { title: "Количество", dataIndex: "amount" },
      { title: "Комментарий", dataIndex: "commentary" }
    ];
  }

  onChange(page,size){

    this.setState({currentPage: page, pageSize: size}, function(){
      this.getWriteOffs();       
    }) 
    
  }

  getWriteOffs(){
    this.setState({ loading: true });
    let params = this.state.filterParams;

    params.page = this.state.currentPage;
    params.pagesize = this.state.pageSize;

    rest.get('/api/WriteOffs/', params).then((data)=>{

      this.setState({writeOffsList:data.data.data.list,totalSize: data.data.data.count,loading:false})
    })
  }

  componentWillMount() {
    document.title = "Списания | Букетная.ОПТ";
    this.getWriteOffs()
  }

  render() {
    const style = { padding: "0px 8px" };
    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,
      <Row key={2}>
        <Col span={this.state.cataspan}>
          <Card className="content-card">
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Списания"
            />
            <div className="writeoffwrapper p10">
            <Spin spinning={this.state.loading}>
              <Table
                columns={this.columns}
                dataSource={this.state.writeOffsList}
                style={{ marginTop: 20 }}
                pagination={false}
              />
              <div className="m10">
                <Pagination
                      defaultCurrent={1}
                      current={this.state.currentPage}
                      defaultPageSize={15}
                      pageSize={this.state.pageSize} 
                      onChange={this.onChange}
                      total={this.state.totalSize}
                      showSizeChanger={false}
                      
                    />
              </div>
            </Spin>
            </div>
          </Card>
        </Col>
      </Row>,
    ];
  }
}

export default withRouter(writeOffs);
