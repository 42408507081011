import React from "react";
import "../../../App.scss";
import { withRouter, NavLink } from "react-router-dom";
import AppContext from "../../../components/common/appContext";
import { Spin, Card, Row, Col, Tooltip, Form, Input, Skeleton, Table, Tag, DatePicker, Space, message, } from "antd";
import { PageHeader, Menu, Dropdown, Button, Tabs } from "antd";
import rest from "../../../helpers/rest";
import * as moment from "moment";
import "moment/locale/ru";
import AppHelper from "../../../helpers/app";
//import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/ru_RU';
import {FileTextOutlined } from "@ant-design/icons";
import TransactionFiles from '../../transactions/files/index'
moment.locale("ru");
const { RangePicker } = DatePicker;





class BalanceHistory extends React.Component {

  
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
        loading : true,
        tableData: [],
        fileopen: false,
        transactionfileid: 0,
    }

    this.getBalanceInfo = this.getBalanceInfo.bind(this);
    this.onSubmit = this.onSubmit.bind(this)

    this.historycolumns = [
      {
        title: 'Тип операции',
        dataIndex: 'typename',
        key: 'typename',
      },
      {
        title: 'Дата',      
        key: 'date',
        render: (text, record) => {
            return moment(record.date).format('DD-MM-YYYY HH:mm')
        }
      },
      {
        title: 'Сумма',
        render: (text, record) => {
            return <Tag color={record.summ > 0 ? 'green' : 'red'}>{AppHelper.toCurrency(
              record.summ,
              "RUB",
              "Ru-ru"
            )}</Tag>
        },
        key: 'summ',
      },
      {
        title: "Вложения",
        key: "attachment",
        render: (text, record) => (
          <a
            onClick={() =>
              this.setState({ fileopen: true, transactionfileid: record.id })
            }
          >
            <FileTextOutlined
              style={{ color: record.files === 0 ? "black" : "" }}
            />
          </a>
        ),
      }
    ];
    
   
  }

 

  componentDidMount() {
    this.getBalanceInfo();
    this.props.onRef(this)
    //console.log(this.context)
  }

  getBalanceInfo(start, end){
    let params = {}    
    if(start){params.start = start;}
    if(end){params.end = end;}
    if(this.props.userid){params.userid = this.props.userid;}else{
      params.userid = this.context.id;
    }
    
    
    this.setState({loading: true})
    rest.get('/api/Transactions', params).then(data => {
        this.setState({loading: false, tableData: data.data.data})
        if(start && end){
            this.formRef.current.setFieldsValue({            
                date: [moment(start), moment(end)]
            })
        }
        
    })
  }

  onSubmit(){
      let formdata = this.formRef.current.getFieldsValue();
      
      if(formdata.date){
        this.getBalanceInfo(moment(formdata.date[0]).format('YYYY-MM-DD'), moment(formdata.date[1]).format('YYYY-MM-DD'))
      }else{
        this.getBalanceInfo(null, null)
      }
      
  }

  render() {
    return [
      <TransactionFiles
        open={this.state.fileopen}
        id={this.state.transactionfileid}
        close={() => {
          this.setState({ fileopen: false });
        }}
        key={8}
      />,
        this.state.loading ? <Skeleton key={1} /> : 
        <div key={1} className="balancehistory">
            <Form
                onFinish={this.onSubmit}
                onFinishFailed={() => message.warn("Заполните данные")}
                name="basic"
                className="useredit"
                ref={this.formRef}
                layout="vertical"
            >
            <div className="userdateselector">
            <Form.Item                  
                  name="date"                  
                >
                    <RangePicker locale={locale}  />     
                  
                </Form.Item>
            
            <Button type="primary" htmlType="submit" style={{marginLeft: '10px'}}>Фильтровать</Button>
            </div>
            </Form>
            <Table dataSource={this.state.tableData} columns={this.historycolumns} />
        </div>
    ];
  }
}

BalanceHistory.contextType = AppContext;

export default withRouter(BalanceHistory);
