import React from "react";
import "../../App.scss";
import { NavLink } from "react-router-dom";
import { message, Skeleton } from "antd";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  Row,
  Col,
  List,
  Modal,
  Button,
  Form,
  Input,
  Spin,
  DatePicker,
  Tabs
} from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm, Table, Tag, Radio } from "antd";
import rest from "../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import locale from "antd/es/date-picker/locale/ru_RU";
import AppHelper from "../../helpers/app";
import * as moment from "moment";
import "moment/locale/ru";
import TotalPrice from "../requests/totalprice";
moment.locale("ru");

const { RangePicker } = DatePicker;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Статистика", key: "/" },
    ];

    this.state = {
      loading: true,
      statictic: [],
      payment: [],
      clients:[],
      recentcustomers:[],
      reportmanagers:[],
      reportmanagersload:false,
      managerTableData: [],
      managerloading: false,
      clientsloading: false,
      recentcustomersloading: false,
      requestsData: [],
      requestsload: true,
      value: 4,
      items: [
        { label: 'Основное', key: 'item-1', children: 'Content 1' },
        { label: 'Клиенты', key: 'item-2', children: 'Content 2' },
        { label: 'Менеджеры', key: 'item-2', children: 'Content 3' }
      ],
      recentcustomerorders: [],
      recentcustomerordersload: true,
      recentcustomerpreorders: [],
      recentcustomerpreordersload: true,
      clientturnover: [],
      clientturnoverload: true,
    };

    this.clientscolumns= [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Пользователь",
        key: "user",
        render: (text, record) => {
          return (
            <NavLink to={`/profile/${record.id}`}>
              {record.sname + " " + record.fname}
            </NavLink>
          );
        },
      },
      {
        title: "Телефон",
        key: "phone",
        render: (text, record) => {
          return record.phone;
        },
      },
    ];

    this.recentcustomerorders= [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Пользователь",
        key: "user",
        render: (text, record) => {
          return (
            <NavLink to={`/profile/${record.id}`}>
              {record.sname + " " + record.fname}
            </NavLink>
          );
        },
      },
      {
        title: "Дата последнего заказа",
        key: "date",
        render: (text, record) => {
          return moment(record.created).format("DD-MM-YYYY в h:mm").replace(/\-/g, '.');
        },
      },
    ];

    this.recentcustomerpreorders= [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Пользователь",
        key: "user",
        render: (text, record) => {
          return (
            <NavLink to={`/profile/${record.id}`}>
              {record.sname + " " + record.fname}
            </NavLink>
          );
        },
      },
      {
        title: "Дата последнего предзаказа",
        key: "date",
        render: (text, record) => {
          return moment(record.DATE_CREATION).format("DD-MM-YYYY в h:mm").replace(/\-/g, '.');
        },
      },
    ];

    this.clientturnover= [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Пользователь",
        key: "user",
        render: (text, record) => {
          return (
            <NavLink to={`/profile/${record.id}`}>
              {record.sname + " " + record.fname}
            </NavLink>
          );
        },
      },
      {
        title: "Сумма всех заказов",
        key: "sum",
        render: (text, record) => {
          return record.sum;
        },
      },
    ];

    this.recentcustomerscolums= [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Пользователь",
        key: "user",
        render: (text, record) => {
          return (
            <NavLink to={`/profile/${record.id}`}>
              {record.sname + " " + record.fname}
            </NavLink>
          );
        },
      },
      {
        title: "Дата звонка",
        key: "date",
        render: (text, record) => {
          return moment(record.createdAt).format("DD-MM-YYYY в h:mm").replace(/\-/g, '.');
        },
      },
      {
        title: "Телефон",
        key: "phone",
        render: (text, record) => {
          return record.phone;
        },
      },
     
    ];

    this.requestcolumns = [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Пользователь",
        key: "user",
        render: (text, record) => {
          return (
            <NavLink to={`/profile/${record.id}`}>
              {record.sname + " " + record.fname}
            </NavLink>
          );
        },
      },
      {
        title: "Баланс",
        key: "balance",
        render: (text, record) => {
          return <Tag color="red">{record.balance}</Tag>;
        },
      },
    ];

    this.reportmanagerscolums = [
      {
        title: "№",
        render: (text, record) => {
          return `№${record.id}`;
        },
        key: "id",
      },
      {
        title: "Менеджер",
        key: "admin",
        render: (text, record) => {
          return (record.sname + " " + record.fname
          
          );
        },
      },
      {
        title: "Количество звонков",
        key: "calls",
        render: (text, record) => {
          return (record.calls );
        },
      },
      {
        title: "Количество заказов",
        key: "requestsCount",
        render: (text, record) => {
          return (record.requests );
        },
      },
      {
        title: "Количество предзаказов",
        key: "preorderCount",
        render: (text, record) => {
          return (record.preorders);
        },
      },
    ];

    this.managercolumns = [
      {
        title: "№",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "ФИО",
        dataIndex: "fio",
        render: (text, record) => {
          return `${record.sname} ${record.fname}`;
        },
      },
      {
        title: "Количество заказов",
        dataIndex: "reqcount",
        key: "reqcount",
      },
      {
        title: "Средний чек",
        dataIndex: "avg",
        render: (text, record) => {
          let avg = record.avg || 0;
          return AppHelper.toCurrency(avg, "RUB", "Ru-ru");
        },
      },
      {
        title: "Сумма отгрузки",
        dataIndex: "totalvalueprice",
        render: (text, record) => {
          let totalvalueprice = record.totalvalueprice || 0;
          return AppHelper.toCurrency(totalvalueprice, "RUB", "Ru-ru");
        },
      },
    ];

    this.walkercolumns = [
      {
        title: "Всего заказов",
        dataIndex: "allreqcount",
        key: "allreqcount",
        render: (text, record) => {
          return `${record.allRequests}`;
        },
      },
      {
        title: "Выданных заказов",
        dataIndex: "avg",
        render: (text, record) => {
          return `${record.issuedRequests}`;
        },
      },
      {
        title: "Выручка",
        dataIndex: "totalvalueprice",
        render: (text, record) => {
          return record.amount ? record.amount : 0;
        },
      },
    ];
    document.title = "Статистика | Букетная.ОПТ";
    this.formRef = React.createRef();
    this.formRef2 = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.getDashboardInfo = this.getDashboardInfo.bind(this);
    this.getManagersInfo = this.getManagersInfo.bind(this);
    this.getmanagersworkreport = this.getmanagersworkreport.bind(this);
    this.getNonPayedRequests = this.getNonPayedRequests.bind(this);
  }

  getNonPayedRequests() {
    rest.get("/api/Account/nonpayed").then((data) => {
      this.setState({ requestsData: data.data.data, requestsload: false });
    });
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      date: [moment(new Date()), moment(new Date())],
    });

    this.setState(
      {
        filterdate: [
          moment(new Date()).format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD"),
        ],
      },
      () => {
        this.getDashboardInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getManagersInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getmanagersworkreport(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.addWalkerInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getturnoverclients(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getrecentcustomerorders(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getrecentcustomerpreorders(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getNonPayedRequests();
      }
    );
    this.getclientsforcaliing();
    this.gettenrecentcustomers();
      console.log(this.state.statictic)
  }

  addWalkerInfo(start = null, end = null) {
    console.log(start)
    rest
      .get("/api/Account/addwalkerinfo", { start: start, end: end })
      .then((data) => {
        this.setState({
          walkerInfo: data.data.data
        });
        console.log(data.data.data);
      });
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
    let dates = [moment(new Date()), moment(new Date())]
    switch(e.target.value){
      case 1 : {
        dates = [
          moment(new Date()).startOf("month").subtract(1, "month"),
          moment(new Date()).endOf("month").subtract(1, "month"),
        ]
        break;
      }
      case 2 : {        
        dates = [
          moment(new Date()).startOf("month"),
          moment(new Date()),
        ]
        break;
      }
      case 3 : {
        //break;
        dates = [
          moment(new Date()).subtract("30", "days"),
          moment(new Date()),
        ]
        break;
      }
      case 4 : {
        dates = [moment(new Date()), moment(new Date())]
        break;
      }
    }
    //console.log(dates)

    this.formRef.current.setFieldsValue({date: dates});

    this.setState({filterdate: dates},
      () => {
        this.getDashboardInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getManagersInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getmanagersworkreport(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getNonPayedRequests();
      }
    );

  
  };

  onSubmit() {

    this.formRef2.current.setFieldsValue({
      radio: false,
    });

    let formdata = this.formRef.current.getFieldsValue();

    this.setState(
      {
        filterdate: [
          moment(formdata.date[0]).format("YYYY-MM-DD"),
          moment(formdata.date[1]).format("YYYY-MM-DD"),
        ],
      },
      () => {
        this.getDashboardInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getManagersInfo(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getmanagersworkreport(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getturnoverclients(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getrecentcustomerorders(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
        this.getrecentcustomerpreorders(
          this.state.filterdate[0],
          this.state.filterdate[1]
        );
      }
    );
  }

  getManagersInfo(start = null, end = null) {
    this.setState({ managerloading: true });
    rest
      .get("/api/Account/managersinfo", { start: start, end: end })
      .then((data) => {
        this.setState({
          managerTableData: data.data.data,
          managerloading: false,
        });
      });
  }

  getDashboardInfo(start = null, end = null) {
    this.setState({ loading: true });
    rest
      .get("/api/Requests/dinfo", {
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD"),
      })
      .then((data) => {
        console.log(data.data.data.statictic)
        this.setState({
          statictic: data.data.data.statictic,
          payment: data.data.data.paymentdata,
          loading: false,
        });
      });
  }

  getclientsforcaliing() {
    this.setState({ clientsloading: true });
    rest
      .get("/api/Account/getclientsforcaliing", {
      })
      .then((data) => {
        this.setState({
          clients: data.data.data,
          clientsloading: false,
        });
      });
  }

  getmanagersworkreport(start = null, end = null) {
    this.setState({ reportmanagersload: true });
    rest
      .get("/api/Account/getmanagersworkreport",  {
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD")
      })
      .then((data) => {
        this.setState({
          reportmanagers: data.data.data,
          reportmanagersload: false,
        });
      });
  }

  gettenrecentcustomers() {
    this.setState({ recentcustomersloading: true });
    rest
      .get("/api/Account/gettenrecentcustomers", {
      })
      .then((data) => {
        this.setState({
          recentcustomers: data.data.data,
          recentcustomersloading: false,
        });
      });
  }

  getturnoverclients(start = null, end = null) {
    this.setState({ clientturnoverload: true });
    rest
      .get("/api/Requests/getturnoverclients",  {
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD")
      })
      .then((data) => {
        console.log(data.data.data)
        this.setState({
          clientturnover: data.data.data,
          clientturnoverload: false,
        });
      });
  }

  getrecentcustomerorders(start = null, end = null) {
    this.setState({ recentcustomerordersload: true });
    rest
      .get("/api/Requests/getrecentcustomerorders",  {
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD")
      })
      .then((data) => {
        this.setState({
          recentcustomerorders: data.data.data,
          recentcustomerordersload: false,
        });
      });
  }

  getrecentcustomerpreorders(start = null, end = null) {
    this.setState({ recentcustomerordersload: true });
    rest
      .get("/api/Requests/getrecentcustomerpreorders",  {
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD")
      })
      .then((data) => {
        this.setState({
          recentcustomerpreorders: data.data.data,
          recentcustomerpreordersload: false,
        });
      });
  }

  render() {
    return [
      <Col key={2} span={24}>
        <Card className="content-card">
          <PageHeader
            className="site-page-header"
            onBack={() => this.props.history.goBack()}
            title="Статистика"
          />
          <Spin spinning={this.state.loading}>
            <div className="p20">
              <Form
                onFinish={this.onSubmit}
                name="basic"
                ref={this.formRef}
                className="useredit"
                layout="vertical"
              >
                <div className="userdateselector">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста выберите диапазон",
                      },
                    ]}
                    name="date"
                  >
                    <RangePicker locale={locale} format='DD-MM-YYYY' />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                  >
                    Показать
                  </Button>
                </div>
              </Form>
              <div>
                <Form
                  name="radio_date"
                  ref={this.formRef2}
                  className="dashfastactions"
                  layout="vertical"
                  initialValues={{radio: 4}}
                >
                  <Form.Item name="radio">
                    <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      onChange={this.onChange}
                      value={4}
                    >
                      <Radio.Button value={1} checked="false">
                        Предыдущий месяц
                      </Radio.Button>
                      <Radio.Button value={2} checked="false">
                        Текущий месяц
                      </Radio.Button>
                      <Radio.Button value={3} checked="false">
                        Последние 30 дней
                      </Radio.Button>
                      <Radio.Button value={4} checked="true">
                        Сегодня
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Form>
              </div>
              
              <div className="dashboard_static_wrapper">
                {this.state.statictic
                  .filter((i) => !i.type)
                  .map((i, index) => {
                    return (
                      <div className="dashboard_static__item" key={index}>
                        {this.state.loading ? (
                          <Skeleton />
                        ) : (
                          <div className="dashboard_static__item_inner">
                            <p className="label" style={{ textAlign: "left" }}>
                              {i.label}
                            </p>
                            <p className="value">{i.value}</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <Tabs>
                <Tabs.TabPane tab="Основное" key="item-1">
                  {this.state.statictic.filter((i) => i.type == "type3").length && (
                    <div className="paymentinfo type1">
                      <h2 style={{ textAlign: "left", marginTop: "50px" }}>
                        Информация о заказах
                      </h2>
                      <div className="dashboard_static_wrapper">
                        {this.state.statictic
                          .filter((i) => i.type == "type3")
                          .map((i, index) => {
                            return (
                              <div className="dashboard_static__item" key={index}>
                              {this.state.loading ? (
                                <Skeleton />
                              ) : (
                              <div className="dashboard_static__item_inner">
                                <p
                                  className="label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i.label}
                                </p>
                                <p className="value">{i.value}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {this.state.statictic.filter((i) => i.type == "type1").length && (
                <div className="paymentinfo type1">
                  <h2 style={{ textAlign: "left", marginTop: "50px" }}>
                    Выручка, прибыль, расходы
                  </h2>
                  <div className="dashboard_static_wrapper">
                    {this.state.statictic
                      .filter((i) => i.type == "type1")
                      .map((i, index) => {
                        return (
                          <div className="dashboard_static__item" key={index}>
                            {this.state.loading ? (
                              <Skeleton />
                            ) : (
                              <div className="dashboard_static__item_inner">
                                <p
                                  className="label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i.label}
                                </p>
                                <p className="value">{i.value}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {this.state.statictic.filter((i) => i.type == "type2").length && (
                <div className="paymentinfo type1">
                  <h2 style={{ textAlign: "left", marginTop: "50px" }}>
                    На текущий момент
                  </h2>
                  <div className="dashboard_static_wrapper">
                    {this.state.statictic
                      .filter((i) => i.type == "type2")
                      .map((i, index) => {
                        return (
                          <div className="dashboard_static__item" key={index}>
                            {this.state.loading ? (
                              <Skeleton />
                            ) : (
                              <div className="dashboard_static__item_inner">
                                <p
                                  className="label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i.label}
                                </p>
                                <p className="value">{i.value}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {this.state.payment.length && (
                <div className="paymentinfo">
                  <h2 style={{ textAlign: "left", marginTop: "50px" }}>
                    Информация об оплате
                  </h2>
                  <div className="dashboard_static_wrapper">
                    {this.state.payment.map((i, index) => {
                      return (
                        <div className="dashboard_static__item" key={index}>
                          <div className="dashboard_static__item_inner">
                            <p className="label" style={{ textAlign: "left" }}>
                              {i.payment}
                            </p>
                            <p className="value">{i.summ}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Клиенты" key="item-2">
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Последние выданные заказы клиентов
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.recentcustomerordersload}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.recentcustomerorders}
                      columns={this.recentcustomerorders}
                    />
                  </Spin>
                </div>
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Последние предзаказы клиентов
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.recentcustomerpreordersload}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.recentcustomerpreorders}
                      columns={this.recentcustomerpreorders}
                    />
                  </Spin>
                </div>
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Информация о товарообороте клиентов
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.clientturnoverload}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.clientturnover}
                      columns={this.clientturnover}
                    />
                  </Spin>
                </div>
                  <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Информация по прохожим
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.requestsload}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.walkerInfo}
                      columns={this.walkercolumns}
                    />
                  </Spin>
                </div>
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Пользователи с отрицательным балансом
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.requestsload}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.requestsData}
                      columns={this.requestcolumns}
                    />
                  </Spin>
                </div>
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Пользователи с неназначенным прозвоном
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.clientsloading}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.clients}
                      columns={this.clientscolumns}
                    />
                  </Spin>
                </div>
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Пользователи с давним прозвоном
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.recentcustomersloading}>
                    <Table
                      pagination={{defaultPageSize: 5}}
                      dataSource={this.state.recentcustomers}
                      columns={this.recentcustomerscolums}
                    />
                  </Spin>
                </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Менеджеры" key="item-3">
                  <div
                  className="managerinfo"
                  style={{ marginTop: "30px", overflow: "hidden" }}
                >
                  <h2 style={{ textAlign: "left" }}>Менеджеры</h2>
                  <Spin
                    spinning={!this.state.loading && this.state.managerloading}
                  >
                    <Table
                      dataSource={this.state.managerTableData}
                      columns={this.managercolumns}
                    />
                  </Spin>
                </div>
                <div className="managerinfo" style={{ marginTop: "30px" }}>
                  <h2 style={{ textAlign: "left" }}>
                    Активность менеджеров
                  </h2>
                  <Spin spinning={!this.state.loading && this.state.reportmanagersload}>
                    <Table
                      dataSource={this.state.reportmanagers}
                      columns={this.reportmanagerscolums}
                    />
                  </Spin>
                </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Spin>
        </Card>
      </Col>,
    ];
  }
}

Dashboard.contextType = AppContext;

export default withRouter(Dashboard);
