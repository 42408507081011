import * as React from "react";
import {
  Breadcrumb,
  Skeleton,
  Form,
  Input,
  Select,
  Button,
  Switch,
  InputNumber,
  Tag,
  Space,
  Table,
  Spin,
  Popconfirm,
  message,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import PaymentAddV2 from "./add";
import { NavLink, withRouter } from "react-router-dom";
import AppHelper from "../../../../../helpers/app";
import rest from "../../../../../helpers/rest";
import * as moment from "moment";

import "moment/locale/ru";
moment.locale("ru");
const { Option } = Select;

class RequestPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      addFormVisible: false,
      top: "none",
      bottom: "none",
      transactionsLoad: true,
      total: 0
    };
    this.paymentTableColumns = [
      {
        title: "Номер",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Тип оплаты",
        dataIndex: "ptname",
        key: "ptname",
      },
      {
        title: "Сумма",
        dataIndex: "summ",
        render: (text, record) =>
          AppHelper.toCurrency(record.summ, "RUB", "Ru-ru"),
      },
      {
        title: "Дата",
        dataIndex: "date",
        render: (text, record) => {
          return moment(record.date).format("DD MMM YYYY");
        },
      },
      {
        title: "Действия",
        render: (text, record) => {
          return (
            <div className="table__actions">
              <Popconfirm
                title="Удалить запись?"
                onConfirm={() => this.deleteTransactions(record.id)}
                okText="Да"
                cancelText="Нет"
              >
                <a href="#">Удалить</a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];
  }

  deleteTransactions = (id) => {
    this.setState({ transactionsLoad: true });
    rest.delete("/api/Transactions", { id: id }).then(() => {
      this.getTransactions();
    });
  };

  componentDidMount() {
    this.getTransactions();
  }

  getTransactions = () => {
    this.setState({ transactionsLoad: true });
    rest
      .get("/api/Transactions/byrequest", { id: this.props.id })
      .then((data) => {
        this.setState({transactions: data.data.data});
        rest.get('/api/Requests/total', {type: this.props.request.type, requestid: this.props.id}).then(total => {
          this.setState({total: total.data.data.total, transactionsLoad: false})
        })
      });
  };

  addToFiscale = () => {
    if(this.getPayLostSum() !== 0){
      message.error('Сумма оплаты не равно сумме заявки')
      return;
    }
    this.setState({ transactionsLoad: true });
    rest
      .post("/api/Requests/addtofiscale", { id: this.props.id })
      .then((data) => {
        this.setState({ transactionsLoad: false });
        message.success("Заявка отправлена на фискализацию");
        this.props.onClose()
      });
  };

  getPayLostSum = () => {
    let paid = 0;    
    this.state.transactions.map(i => {paid += i.summ});    
    return this.state.total - paid
  }

  render() {
    return [
      <h3 key={1}>Оплата по заявке {!this.state.transactionsLoad && <span>| Доплата : {AppHelper.toCurrency(this.getPayLostSum(),"RUB","Ru-ru")}</span>}</h3>,
      <Spin key={2} spinning={this.state.transactionsLoad}>
        <Table
          key={2}
          dataSource={this.state.transactions}
          pagination={false}
          columns={this.paymentTableColumns}
        />
      </Spin>,
      this.state.addFormVisible && (
        <PaymentAddV2
          onAdd={() => {
            this.getTransactions();
            this.setState({ addFormVisible: false });
          }}
          request={this.props.id}
          key={3}
        />
      ),
      <div key={4} className="payment__actions">
        <Button
          onClick={() =>
            this.setState({ addFormVisible: !this.state.addFormVisible })
          }
          icon={<PlusOutlined />}
        >
          {this.state.addFormVisible ? "Скрыть" : "Добавить"}{" "}
        </Button>
        <Popconfirm
          title="Отправить заявку на фискализацию"
          onConfirm={() => this.addToFiscale()}
          okText="Да"
          cancelText="Нет"
        >
          <Button type="primary">
            Фискализировать заявку
          </Button>
        </Popconfirm>
      </div>,
    ];
  }
}

export default withRouter(RequestPayment);
