import * as React from 'react';
import {Select, Form } from 'antd';
import rest from '../../helpers/rest'
const { Option } = Select;

const sortdata = [
    {name: 'По возрастанию роста', code: 'growthup'},
    {name: 'По убыванию роста', code: 'growthdown'},
    {name: 'По возрастанию цены', code: 'priceup'},
    {name: 'По убыванию цены', code: 'pricedown'},
    {name: 'По наименованию A-Я', code: 'nameup'},
    {name: 'По наименованию Я-А', code: 'namedown'},
]

class SortSelect extends React.Component {
    constructor(props) {
        super(props);
        let sort = sortdata;
        if(this.props.hidePrice){
            sort = sort.filter(item => (item.code !== 'pricedown') && (item.code !== 'priceup'))
        }
        if(this.props.hideGrowth){
            sort = sort.filter(item => (item.code !== 'growthup') && (item.code !== 'growthdown'))
        }
        this.state = {
            sortlist: this.props.options || sort
        };        
        
    } 

    

    render() {        
        return(
            <Form.Item
                            label={this.props.label || 'Сортировка'}
                            name={this.props.name || 'sort'}                             
                        >
            <Select  onChange={() => {
                if(this.props.sort){
                    this.props.sort()
                }
            }}                      
                        style={{ width: '100%' }}
                        placeholder={this.props.placeholdertext || 'Сортировать'}
                      >
                        {this.state.sortlist.map((item, i) => {
                            return <Option label={item.name} value={item.code} key={i.toString(36) + i}>                                
                                {item.name}                                 
                            </Option>
                        })}
                      </Select>
                      </Form.Item>
        );
    }
}

export default SortSelect;
