import React from "react";
import "../../App.scss";
import { message, Skeleton } from "antd";
import Breadcrumb from "../common/breadcrumb";
import { Card, Row, Col, List, Modal, Button, Form, Input } from "antd";
import AppContext from "../../components/common/appContext";
import { PageHeader, Popconfirm } from "antd";
import rest from "../../helpers/rest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Loading from "../loading";
import { withRouter } from "react-router-dom";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class Colors extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { title: "Букетная.ОПТ", key: "/" },
      { title: "Справочники", key: "/directory/" },
      { title: "Цвета", key: "/colors/" },
    ];
    this.formRef = React.createRef();
    this.formRefEdit = React.createRef();
    this.history = this.props.history;
    this.state = {
      cataspan: 24,
      colorList: [1,2],
      loading: true,
      addModalVisible: false,
      formAddLoader: false,
      editModalVisible: false,
      activeColor: {},
      displayColorPicker: false,
      value: '#fff',
    };
    document.title = "Цвета | Букетная.ОПТ";
    this.getColors = this.getColors.bind(this);

    this.addModalShow = this.addModalShow.bind(this);
    this.closeAddModa = this.closeAddModa.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this)
    this.submitEditForm = this.submitEditForm.bind(this)
    this.onSubmit = this.onSubmit.bind(this);
    this.submitAddForm = this.submitAddForm.bind(this);
    this.showEditColor = this.showEditColor.bind(this)
    this.closeEditModa = this.closeEditModa.bind(this)
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ value: color.hex })
  };

  componentWillMount() {
    this.getColors();
  }

  getColors() {
    this.setState({ loading: true });
    rest.get("/api/Colors").then((colors) => {
      this.setState({ colorList: colors.data.data, loading: false });
    });
  }

  onSubmit() {
    let formdata = this.formRef.current.getFieldsValue();
    formdata.value = this.state.value
    
    this.setState({ formAddLoader: true });
    rest.post("/api/Colors", formdata).then((res) => {
      
      this.closeAddModa();
      this.getColors();
    });
  }

  onSubmitEdit() {
    let formdata = this.formRefEdit.current.getFieldsValue();
    formdata.value = this.state.value
    formdata.id = this.state.activeColor.id
    this.setState({ formAddLoader: true });
    
    rest.update("/api/Colors/", formdata).then((res) => {
      
      this.closeAddModa();
      this.getColors();      
    });
  }

  addModalShow() {
    this.setState({ addModalVisible: true });
  }

  closeAddModa() {
    this.setState({editModalVisible: false, addModalVisible: false, formAddLoader: false });
  }
  closeEditModa(){
    this.setState({ editModalVisible: false, formAddLoader: false });
  }

  submitAddForm() {
    this.formRef.current.submit();  
  }

  submitEditForm() {
    this.formRefEdit.current.submit();  
  }

  deleteColor(id){
      this.setState({loading: true})
      rest.delete('/api/Colors', {id:id}).then(() => {
          this.getColors();
          message.success('Цвет удален');          
      })
  }

  showEditColor(item){
      this.setState({editModalVisible: true, activeColor: item})
      this.setState({ value: item.value })
      setTimeout(() => {
        this.formRefEdit.current.setFieldsValue({name: item.name})
      }, 100)
      
      //
  }

  render() {
    const style = { padding: "0px 20px" };
    const pickerStyle = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: this.state.value
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });

    return [
      <Breadcrumb
        key={1}
        style={{ margin: "16px 0" }}
        list={this.breadcrumbs}
      />,

      <Card key={2} className="content-card">
        <Row key={2}>
          <Col span={this.state.cataspan}>
            <PageHeader
              className="site-page-header"
              onBack={() => this.props.history.goBack()}
              title="Цвета"
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.addModalShow()}
                >
                  Добавить
                </Button>,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={style} className="categorylist">
            <List
              itemLayout="horizontal"
              dataSource={this.state.colorList}
              locale={{emptyText: 'Цвета не найдены'}}
              renderItem={(item, index) => (
                <Skeleton
                  key={index}
                  title={false}
                  loading={this.state.loading}
                  active
                >
                  <List.Item
                    className="coloranizationList"
                    actions={[
                      <a key="list-loadmore-edit" onClick={() => this.showEditColor(item)}>
                        <EditOutlined />
                      </a>,
                      <Popconfirm
                        title="Удалить цвет?"
                        onConfirm={() => this.deleteColor(item.id)}                        
                        okText="Да"
                        cancelText="Нет"
                      >
                        <a key="list-loadmore-more">
                          <DeleteOutlined />
                        </a>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta title={item.name} />

                <div
                    className="colordisplay"
                    style={{ background: item.value }}
                  ></div>

                  </List.Item>
                </Skeleton>
              )}
            ></List>
          </Col>
        </Row>
      </Card>,
      <Modal
      key={3}
      title="Редактирование Цвета"
      visible={this.state.editModalVisible}
      onCancel={this.closeEditModa}
      onOk={this.submitEditForm}
    >
      {this.state.formAddLoader ? (
        <Loading mini={true} />
      ) : (
        <Form
          {...layout}
          name="addcolorform"
          ref={this.formRefEdit}
          onFinish={this.onSubmitEdit}
        >
          <Form.Item
            label="Название цвета"
            name="name"
            rules={[
              { required: true, message: "Пожалуйста введите название цвета" },
            ]}
          >
            <Input />
          </Form.Item>
          
          <Form.Item
              label="Цвет"
              name="value"
              rules={[]}
            >
                <div>
                    <div style={ pickerStyle.swatch } onClick={ this.handleClick }>
                    <div style={ pickerStyle.color } />
                </div>
                { this.state.displayColorPicker ? <div style={ pickerStyle.popover }>
                <div style={ pickerStyle.cover } onClick={ this.handleClose }/>
                <SketchPicker color={ this.state.value } onChange={ this.handleChange } />
                </div> : null }

                </div>
            

           
            </Form.Item>

        </Form>
      )}
    </Modal>,
      <Modal
        key={4}
        title="Добавление цвета"
        visible={this.state.addModalVisible}
        onCancel={this.closeAddModa}
        onOk={this.submitAddForm}
      >
        {this.state.formAddLoader ? (
          <Loading mini={true} />
        ) : (
          <Form
            {...layout}
            name="addorgform"
            ref={this.formRef}
            onFinish={this.onSubmit}
          >
            <Form.Item
              label="Название цвета"
              name="name"
              rules={[
                { required: true, message: "Пожалуйста введите название цвета" },
              ]}
            >
              <Input />

           
            </Form.Item>

          
          <Form.Item
              label="Цвет"
              name="color"
            >
            <div>
            <div style={ pickerStyle.swatch } onClick={ this.handleClick }>
                <div style={ pickerStyle.color } />
            </div>
            { this.state.displayColorPicker ? <div style={ pickerStyle.popover }>
            <div style={ pickerStyle.cover } onClick={ this.handleClose }/>
            <SketchPicker color={ this.state.value } onChange={ this.handleChange } />
            </div> : null }
            </div>

           
            </Form.Item>
          

           
          </Form>
        )}
      </Modal>,
    ];
  }
}

Colors.contextType = AppContext;

export default withRouter(Colors);
